:root {
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.TestimonialsSection.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.TestimonialsSection.to-gray-50 {
  --tw-gradient-to: #f9fafb var(--tw-gradient-to-position);
}

.TestimonialsSection.from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.TestimonialsSection.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.TestimonialsSection .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.TestimonialsSection .max-w-6xl {
  max-width: 72rem;
}

.TestimonialsSection .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.TestimonialsSection .text-center {
  text-align: center;
}

.TestimonialsSection .mb-16 {
  margin-bottom: 4rem;
}

.TestimonialsSection .text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(93 74 247 / var(--tw-text-opacity));
}

.TestimonialsSection .tracking-wide {
  letter-spacing: 0.025em;
}

.TestimonialsSection .font-medium {
  font-weight: 700;
}

.TestimonialsSection .font-heading {
  font-family: Clash Display, sans-serif;
}

.TestimonialsSection .block {
  display: block;
}

.TestimonialsSection .mb-4 {
  margin-bottom: 1rem !important;
}

@media (min-width: 1024px) {
  .TestimonialsSection .lg\:text-5xl {
    font-size: 3rem !important;
    line-height: 1 !important;
  }
}

.TestimonialsSection .text-transparent {
  color: transparent;
}

.TestimonialsSection .text-navy-900 {
  --tw-text-opacity: 1;
  color: rgb(26 34 53 / var(--tw-text-opacity));
}

.TestimonialsSection .font-bold {
  font-weight: 700;
}

.TestimonialsSection .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.TestimonialsSection .font-heading {
  font-family: Clash Display, sans-serif !important;
}

.TestimonialsSection .bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.TestimonialsSection .to-primary-900 {
  background: linear-gradient(90deg, #1a2235, #382f89);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.TestimonialsSection blockquote,
.TestimonialsSection dl,
.TestimonialsSection dd,
.TestimonialsSection h1,
.TestimonialsSection h2,
.TestimonialsSection h3,
.TestimonialsSection h4,
.TestimonialsSection h5,
.TestimonialsSection h6,
.TestimonialsSection hr,
.TestimonialsSection figure,
.TestimonialsSection p,
.TestimonialsSection pre {
  margin: 0;
}

.TestimonialsSection h1,
.TestimonialsSection h2,
.TestimonialsSection h3,
.TestimonialsSection h4,
.TestimonialsSection h5,
.TestimonialsSection h6 {
  font-size: inherit;
  font-weight: inherit;
}

@media (min-width: 768px) {
  .TestimonialsSection .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.TestimonialsSection .gap-8 {
  gap: 2rem;
}

.TestimonialsSection .grid {
  display: grid;
}

.TestimonialsSection .duration-300 {
  transition-duration: 300ms;
}

.TestimonialsSection .transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.TestimonialsSection .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important ;
}

.TestimonialsSection .hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.TestimonialsSection .p-8 {
  padding: 2rem;
}

.TestimonialsSection .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.TestimonialsSection .border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.TestimonialsSection .border {
  border-width: 1px !important;
}

.TestimonialsSection .rounded-2xl {
  border-radius: 1rem;
}

.TestimonialsSection .gap-4 {
  gap: 1rem;
}

.TestimonialsSection .items-center {
  align-items: center;
}

.TestimonialsSection .flex {
  display: flex;
}

.TestimonialsSection .mb-6 {
  margin-bottom: 1.5rem;
}

.TestimonialsSection .relative {
  position: relative;
}

.TestimonialsSection .bg-primary-400\/20 {
  background-color: rgb(139 143 255 / 0.2);
}

.TestimonialsSection .rounded-full {
  border-radius: 9999px;
}

.TestimonialsSection .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.TestimonialsSection .translate-y-1 {
  --tw-translate-y: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.TestimonialsSection .translate-x-1 {
  --tw-translate-x: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.TestimonialsSection .inset-0 {
  inset: 0px;
}

.TestimonialsSection .absolute {
  position: absolute;
}

.TestimonialsSection .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.TestimonialsSection .rounded-full {
  border-radius: 9999px;
}

.TestimonialsSection .w-16 {
  width: 4rem;
}

.TestimonialsSection .h-16 {
  height: 4rem;
}

.TestimonialsSection .z-10 {
  z-index: 10;
}

.TestimonialsSection .relative {
  position: relative;
}

.TestimonialsSection img,
video {
  max-width: 100%;
  height: auto;
}

.TestimonialsSection img,
.TestimonialsSection svg,
.TestimonialsSection video,
.TestimonialsSection canvas,
.TestimonialsSection audio,
.TestimonialsSection iframe,
.TestimonialsSection embed,
.TestimonialsSection object {
  display: block;
  vertical-align: middle;
}

.TestimonialsSection .font-semibold {
  font-weight: 600;
}

.TestimonialsSection .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.TestimonialsSection .text-primary-200 {
  --tw-text-opacity: 1;
  color: rgb(203 217 255 / var(--tw-text-opacity));
}

.TestimonialsSection .-scale-x-100 {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.TestimonialsSection .w-8 {
  width: 2rem;
}

.TestimonialsSection .h-8 {
  height: 2rem;
}

.TestimonialsSection .-top-2 {
  top: -0.5rem;
}

.TestimonialsSection .-left-2 {
  left: -0.5rem;
}

.TestimonialsSection .text-navy-700 {
  --tw-text-opacity: 1;
  color: rgb(53 73 116 / var(--tw-text-opacity));
}

.TestimonialsSection .leading-relaxed {
  line-height: 1.625;
}

.TestimonialsSection .pl-6 {
  padding-left: 1.5rem;
}

.TestimonialsSection .p-6 {
  padding: 1.5rem;
}

.TestimonialsSection .bg-navy-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 246 251 / var(--tw-bg-opacity));
}

.TestimonialsSection .border-navy-100 {
  --tw-border-opacity: 1;
  border-color: rgb(232 236 245 / var(--tw-border-opacity));
}

.TestimonialsSection .rounded-xl {
  border-radius: 0.75rem;
}

.TestimonialsSection .justify-between {
  justify-content: space-between;
}

.TestimonialsSection .items-center {
  align-items: center;
}

.TestimonialsSection .flex {
  display: flex;
}

.TestimonialsSection .gap-1 {
  gap: 0.25rem;
}

.TestimonialsSection .text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(107 102 255 / var(--tw-text-opacity));
}

.TestimonialsSection .fill-current {
  fill: currentColor;
}

.TestimonialsSection .w-5 {
  width: 1.25rem;
}

.TestimonialsSection .h-5 {
  height: 1.25rem;
}

.TestimonialsSection .hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
