.E_Trainings_Verivication.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.E_Trainings_Verivication.min-h-screen {
  min-height: 100vh;
}

.E_Trainings_Verivication .py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.E_Trainings_Verivication .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.E_Trainings_Verivication .max-w-4xl {
  max-width: 56rem;
}

.E_Trainings_Verivication .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .E_Trainings_Verivication .lg\:grid-cols-\[1fr\2c 360px\] {
    grid-template-columns: 1fr 360px;
  }
}

.E_Trainings_Verivication .gap-8 {
  gap: 2rem;
}

.E_Trainings_Verivication .grid {
  display: grid;
}

@media (min-width: 1024px) {
  .E_Trainings_Verivication .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }
}

.E_Trainings_Verivication .space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.E_Trainings_Verivication .max-w-lg {
  max-width: 32rem;
}

.E_Trainings_Verivication .w-full {
  width: 100%;
}

.E_Trainings_Verivication .p-3 {
  padding: 0.75rem;
}

.E_Trainings_Verivication .bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.E_Trainings_Verivication .rounded-full {
  border-radius: 9999px;
}

.E_Trainings_Verivication .justify-center {
  justify-content: center;
}

.E_Trainings_Verivication .items-center {
  align-items: center;
}

.E_Trainings_Verivication .inline-flex {
  display: inline-flex;
}

.E_Trainings_Verivication .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.E_Trainings_Verivication .w-6 {
  width: 1.5rem;
}

.E_Trainings_Verivication .h-6 {
  height: 1.5rem;
}

.E_Trainings_Verivication img,
.E_Trainings_Verivication svg,
.E_Trainings_Verivication video,
.E_Trainings_Verivication canvas,
.E_Trainings_Verivication audio,
.E_Trainings_Verivication iframe,
.E_Trainings_Verivication embed,
.E_Trainings_Verivication object {
  display: block;
  vertical-align: middle;
}

.E_Trainings_Verivication .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.E_Trainings_Verivication .max-w-md {
  max-width: 28rem;
}

.E_Trainings_Verivication .relative {
  position: relative;
}

.E_Trainings_Verivication .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.E_Trainings_Verivication .-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.E_Trainings_Verivication .w-5 {
  width: 1.25rem;
}

.E_Trainings_Verivication .h-5 {
  height: 1.25rem;
}

.E_Trainings_Verivication .top-1\/2 {
  top: 50%;
}

.E_Trainings_Verivication .left-3 {
  left: 0.75rem;
}

.E_Trainings_Verivication .absolute {
  position: absolute;
}

.E_Trainings_Verivication img,
.E_Trainings_Verivication svg,
.E_Trainings_Verivication video,
.E_Trainings_Verivication canvas,
.E_Trainings_Verivication audio,
.E_Trainings_Verivication iframe,
.E_Trainings_Verivication embed,
.E_Trainings_Verivication object {
  display: block;
  vertical-align: middle;
}

.E_Trainings_Verivication .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.E_Trainings_Verivication .outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.E_Trainings_Verivication .pr-4 {
  padding-right: 1rem;
}

.E_Trainings_Verivication .pl-10 {
  padding-left: 2.5rem;
}

.E_Trainings_Verivication .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.E_Trainings_Verivication .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.E_Trainings_Verivication .border {
  border-width: 1px;
}

.E_Trainings_Verivication .rounded-lg {
  border-radius: 0.5rem;
}

.E_Trainings_Verivication .w-full {
  width: 100%;
}

.E_Trainings_Verivication button,
.E_Trainings_Verivication input,
.E_Trainings_Verivication optgroup,
.E_Trainings_Verivication select,
.E_Trainings_Verivication textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.E_Trainings_Verivication .disabled\:bg-blue-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.E_Trainings_Verivication .hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.E_Trainings_Verivication .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.E_Trainings_Verivication .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.E_Trainings_Verivication .font-medium {
  font-weight: 500;
}

.E_Trainings_Verivication .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.E_Trainings_Verivication .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.E_Trainings_Verivication .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.E_Trainings_Verivication .rounded-lg {
  border-radius: 0.5rem;
}

.E_Trainings_Verivication .w-full {
  width: 100%;
}

.E_Trainings_Verivication .mt-4 {
  margin-top: 1rem;
}

.E_Trainings_Verivication :disabled {
  cursor: default;
}

.E_Trainings_Verivication button,
[role="button"] {
  cursor: pointer;
}

.E_Trainings_Verivication button,
.E_Trainings_Verivication input:where([type="button"]),
.E_Trainings_Verivication input:where([type="reset"]),
.E_Trainings_Verivication input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.E_Trainings_Verivication button,
.E_Trainings_Verivication select {
  text-transform: none;
}

.E_Trainings_Verivication button,
.E_Trainings_Verivication input,
.E_Trainings_Verivication optgroup,
.E_Trainings_Verivication select,
.E_Trainings_Verivication textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.E_Trainings_Verivication .space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.E_Trainings_Verivication .text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.E_Trainings_Verivication .p-4 {
  padding: 1rem;
}

.E_Trainings_Verivication .bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.E_Trainings_Verivication .rounded-lg {
  border-radius: 0.5rem;
}

.E_Trainings_Verivication .gap-2 {
  gap: 0.5rem;
}

.E_Trainings_Verivication .items-center {
  align-items: center;
}

.E_Trainings_Verivication .flex {
  display: flex;
}

.E_Trainings_Verivication .text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.E_Trainings_Verivication .bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.E_Trainings_Verivication .profile-card .header {
  background: linear-gradient(135deg, #1a1a1a 0, #3f37c9 100%);
  color: #fff;
  padding: 35px 30px;
  position: relative;
  text-align: center;
}

.E_Trainings_Verivication .profile-card .header:after {
  background: linear-gradient(to bottom right, #0000 49%, #fff 51%);
  bottom: 0;
  content: "";
  height: 40px;
  left: 0;
  position: absolute;
  right: 0;
}

.E_Trainings_Verivication .profile-card {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 35px #1a237e1a;
  max-width: 480px;
  overflow: hidden;
  width: 100%;
}

.E_Trainings_Verivication .profile-card .content {
  padding: 30px;
}

.E_Trainings_Verivication .profile-card .profile-image {
  border: 4px solid #fffc;
  border-radius: 50%;
  box-shadow: 0 5px 15px #0003;
  height: 130px;
  margin: auto;
  margin-bottom: 15px !important;
  object-fit: cover;
  width: 130px;
}

.E_Trainings_Verivication .profile-card .participant-label {
  font-size: 14px;
  letter-spacing: 2px;
  margin-bottom: 8px;
  opacity: 0.9;
  text-transform: uppercase;
}

.E_Trainings_Verivication .profile-card .profile-name {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
}

.E_Trainings_Verivication .profile-card .domain-title {
  font-family: Orbitron, sans-serif;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.E_Trainings_Verivication .profile-card .section-title {
  color: #546e7a;
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 25px;
}

.E_Trainings_Verivication .profile-card .formation-item {
  align-items: center;
  background: #f5f7fa;
  border: 1px solid #0000;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 16px 20px;
  transition: all 0.3s ease;
}

.E_Trainings_Verivication .profile-card .formation-item:hover {
  background: #fff;
  border: 1px solid #3949ab;
  box-shadow: 0 5px 15px #1a237e14;
  transform: translateX(5px);
}

.E_Trainings_Verivication .profile-card .formation-name {
  color: #2c3e50;
  color: var(--text-primary);
  flex: 1 1;
  font-size: 15px;
  font-weight: 500;
  margin-right: 15px;
}

.E_Trainings_Verivication .profile-card .arrow-icon {
  align-items: center;
  background: #3949ab;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: all 0.3s ease;
  width: 40px;
}

.E_Trainings_Verivication .profile-card .arrow-icon svg {
  color: #fff;
  height: 20px;
  width: 20px;
}

.E_Trainings_Verivication .profile-card .evaluation-section {
  background: #f5f7fa;
  border-radius: 15px;
  margin-top: 30px;
  padding: 20px;
}

.E_Trainings_Verivication .profile-card .evaluation-title {
  color: #2c3e50;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.E_Trainings_Verivication .profile-card .detail-button {
  align-items: center;
  background: #3949ab;
  border-radius: 25px;
  box-shadow: 0 4px 15px #1a237e33;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 8px;
  padding: 10px 24px;
  text-decoration: none;
  transition: all 0.3s ease;
  justify-content: space-between;
}

.E_Trainings_Verivication .profile-card .detail-button:hover {
  background: #5c6bc0;
  box-shadow: 0 6px 20px #1a237e40;
  transform: translateY(-2px);
}
