.Timar.fh5co-cover {
  height: 900px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
}

.Timar.fh5co-cover .fh5co-cover .overlay {
  z-index: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.Timar .container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/* @media (min-width: 1200px) {
  .Timar .container {
    width: 1170px;
  }
}

@media (min-width: 992px) {
  .Timar .container {
    width: 970px;
  }
}

@media (min-width: 768px) {
  .Timar .container {
    width: 750px;
  }
}

.Timar .container:before,
.Timar .container:after {
  content: " ";
  display: table;
} */

.Timar .row {
  margin-left: -15px;
  margin-right: -15px;
}

.Timar .row:before,
.Timar .row:after {
  content: " ";
  display: table;
}

@media (min-width: 992px) {
  .Timar .col-md-offset-2 {
    margin-left: 16.66667%;
  }
}

@media (min-width: 992px) {
  .Timar .col-md-8 {
    width: 66.66667%;
  }
}

.Timar .fh5co-cover .display-t,
.Timar .fh5co-cover .display-tc {
  height: 900px;
  display: table;
  width: 100%;
  padding-top: 20px;
}

.Timar #fh5co-header .display-tc,
.Timar .fh5co-cover .display-tc {
  display: table-cell !important;
  vertical-align: middle;
}

.Timar .js .animate-box {
  opacity: 0;
}

.Timheaderar .fh5co-cover .display-t,
.Timar .fh5co-cover .display-tc {
  height: 900px;
  display: table;
  width: 100%;
  padding-top: 20px;
}

.Timar #fh5co-header .display-tc h1,
.Timar .fh5co-cover .display-tc h1 {
  margin-bottom: 20px;
  font-size: 60px;
  line-height: 1.5;
  font-family: "Work Sans", Arial, sans-serif;
  font-weight: 300;
}

.Timar #fh5co-header .display-tc h1,
.Timar #fh5co- .display-tc h2,
.Timar .fh5co-cover .display-tc h1,
.Timar .fh5co-cover .display-tc h2 {
  margin: 0;
  padding: 0;
  color: white;
}

.Timar #fh5co-header .display-tc h2,
.Timar .fh5co-cover .display-tc h2 {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 40px;
}

.Timar #fh5co-header .display-tc h1,
.Timar #fh5co-header .display-tc h2,
.Timar .fh5co-cover .display-tc h1,
.Timar .fh5co-cover .display-tc h2 {
  margin: 0;
  padding: 0;
  color: white;
}

.Timar .simply-countdown {
  margin-top: 3em;
  margin-bottom: 3em;
}

.Timar .simply-countdown > .simply-section {
  display: inline-block;
  width: 110px;
  height: 120px;
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 4px solid rgba(250, 70, 89, 0.5);
  margin: 0 7px;
  position: relative;
  /* animation: pulse 1.01s ease infinite; */
}

.Timar .simply-countdown > .simply-section > div {
  display: table-cell;
  vertical-align: middle;
  height: 100px;
  width: 110px;
}

.Timar .simply-countdown > .simply-section .simply-amount {
  font-size: 40px;
}

.Timar .simply-countdown > .simply-section .simply-amount,
.Timar .simply-countdown > .simply-section .simply-word {
  display: block;
  color: white;
}
