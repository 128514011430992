:root {
  --muted-foreground: 0 0% 45.1%;
  --border: 0 0% 89.8%;
  --background: 0 0% 100%;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --ring: 0 0% 3.9%;
  --primary-foreground: 0 0% 98%;
  --primary: 0 0% 9%;
  --radius: 0.5rem;
  --input: 0 0% 89.8%;
  --primary-foreground: 0 0% 98%;
}

.FomateurRegister .text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.FomateurRegister .tracking-tight {
  letter-spacing: -0.025em;
}

.FomateurRegister .font-bold {
  font-weight: 700;
}

.FomateurRegister .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.FomateurRegister blockquote,
.FomateurRegister dl,
.FomateurRegister dd,
.FomateurRegister h1,
.FomateurRegister h2,
.FomateurRegister h3,
.FomateurRegister h4,
.FomateurRegister h5,
.FomateurRegister h6,
.FomateurRegister hr,
.FomateurRegister figure,
.FomateurRegister p,
.FomateurRegister pre {
  margin: 0;
}

.FomateurRegister .space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}

.FomateurRegister .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.FomateurRegister .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.FomateurRegister text-muted-foreground {
  color: hsl(var(--muted-foreground));
}

.FomateurRegister .p-1 {
  padding: 0.25rem;
}

.FomateurRegister .bg-muted\/50 {
  background-color: hsl(var(--muted) / 0.5);
}

.FomateurRegister .rounded-lg {
  border-radius: var(--radius);
}

.FomateurRegister .gap-4 {
  gap: 1rem;
}

.FomateurRegister .justify-center {
  justify-content: center;
}

.FomateurRegister .items-center {
  align-items: center;
}

.FomateurRegister .grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.FomateurRegister .h-9 {
  height: 2.25rem;
}

.FomateurRegister .grid {
  display: grid;
}

.FomateurRegister * {
  border-color: hsl(var(--border));
}

.FomateurRegister .text-center {
  text-align: center;
}

.FomateurRegister .p-6 {
  padding: 1.5rem;
}

.FomateurRegister .flex-col {
  flex-direction: column;
}

.FomateurRegister .flex {
  display: flex;
}

.FomateurRegister .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.FomateurRegister .ring-offset-background {
  --tw-ring-offset-color: hsl(var(--background));
}

.FomateurRegister {
  font-weight: 500;
}

.FomateurRegister .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.FomateurRegister .py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.FomateurRegister .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

@media (min-width: 768px) {
  .FomateurRegister .md\:inline {
    display: inline;
  }
}

.FomateurRegister .hidden {
  display: none;
}

.FomateurRegister .data-\[state\=active\]\:shadow[data-state='active'] {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.FomateurRegister
  .data-\[state\=active\]\:text-foreground[data-state='active'] {
  color: hsl(var(--foreground));
}

.FomateurRegister .data-\[state\=active\]\:bg-white[data-state='active'] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.FomateurRegister button:hover {
  border-color: #646cff;
}

.FomateurRegister .inline-flex {
  display: inline-flex;
}

.FomateurRegister .button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

@media (min-width: 768px) {
  .FomateurRegister .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.FomateurRegister .gap-6 {
  gap: 1.5rem;
}

.FomateurRegister .md\:grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.FomateurRegister .leading-none {
  line-height: 1;
}

.FomateurRegister input:focus {
  border: 1px solid;
}

.FomateurRegister .shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* .FomateurRegister .text-primary-foreground {
  color: hsl(var(--primary-foreground)) !important ;
} */

/* .FomateurRegister .bg-primary {
  background-color: hsl(var(--primary)) !important;
} */

.FomateurRegister .rounded-md {
  border-radius: calc(var(--radius) - 2px) !important;
}

.FomateurRegister .disabled\:opacity-50:disabled {
  opacity: 0.5 !important;
}

.FomateurRegister .disabled\:pointer-events-none:disabled {
  pointer-events: none !important;
}

.FomateurRegister .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.FomateurRegister .border-input {
  border-color: hsl(var(--input)) !important ;
}

.FomateurRegister .inline-flex {
  display: inline-flex !important ;
}

.FomateurRegister :disabled {
  cursor: default !important ;
}
/* 
.FomateurRegister .border-primary {
  border-color: hsl(var(--primary));
} */

.FomateurRegister .border {
  border-width: 1px;
}

.FomateurRegister .rounded-sm {
  border-radius: calc(var(--radius) - 4px);
}

.FomateurRegister .shrink-0 {
  flex-shrink: 0;
}

.FomateurRegister .w-4 {
  width: 1rem;
}

.FomateurRegister .h-4 {
  height: 1rem;
}

.FomateurRegister .submit-btn {
  background: rgb(26, 26, 26) !important;
  color: rgb(255, 255, 255);
}
