@media (min-width: 1024px) {
  .Tableau_De_Bord_Financier .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 640px) {
  .Tableau_De_Bord_Financier .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.Tableau_De_Bord_Financier .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.Tableau_De_Bord_Financier .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Tableau_De_Bord_Financier .max-w-7xl {
  max-width: 80rem;
}

.Tableau_De_Bord_Financier .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .Tableau_De_Bord_Financier .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.Tableau_De_Bord_Financier .gap-4 {
  gap: 1rem;
}

.Tableau_De_Bord_Financier .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.Tableau_De_Bord_Financier .grid {
  display: grid;
}

.Tableau_De_Bord_Financier .mb-8 {
  margin-bottom: 2rem;
}

.Tableau_De_Bord_Financier .shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Tableau_De_Bord_Financier .p-6 {
  padding: 1.5rem;
}

.Tableau_De_Bord_Financier .rounded-lg {
  border-radius: 0.5rem;
}

.Tableau_De_Bord_Financier .w-8 {
  width: 2rem;
}

.Tableau_De_Bord_Financier .h-8 {
  height: 2rem;
}

.Tableau_De_Bord_Financier .items-center {
  align-items: center;
}

.Tableau_De_Bord_Financier .flex {
  display: flex;
}

.Tableau_De_Bord_Financier .text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.Tableau_De_Bord_Financier .text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.Tableau_De_Bord_Financier .overflow-x-auto {
  overflow-x: auto;
}

.Tableau_De_Bord_Financier .min-w-full {
  min-width: 100%;
}

.Tableau_De_Bord_Financier table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

.Tableau_De_Bord_Financier .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.Tableau_De_Bord_Financier .tracking-wider {
  letter-spacing: 0.05em;
}

.Tableau_De_Bord_Financier .uppercase {
  text-transform: uppercase;
}

.Tableau_De_Bord_Financier .font-medium {
  font-weight: 500;
}

.Tableau_De_Bord_Financier .text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.Tableau_De_Bord_Financier .text-left {
  text-align: left;
}

.Tableau_De_Bord_Financier .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.Tableau_De_Bord_Financier .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.Tableau_De_Bord_Financier .text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.Tableau_De_Bord_Financier .bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.Tableau_De_Bord_Financier .text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

.Tableau_De_Bord_Financier .bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.Tableau_De_Bord_Financier .text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.Tableau_De_Bord_Financier .text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
