.Formations .shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Formations .p-6 {
  padding: 1.5rem;
}

.Formations .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.Formations .rounded-lg {
  border-radius: 0.5rem;
}

.Formations .mb-6 {
  margin-bottom: 1.5rem;
}

.Formations .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.Formations .font-bold {
  font-weight: 700;
}

.Formations .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.Formations .grid {
  display: grid;
}

.Formations .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.Formations .gap-4 {
  gap: 1rem;
}

@media (min-width: 768px) {
  .Formations .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.Formations .p-4 {
  padding: 1rem;
}

.Formations .bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.Formations .bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.Formations .text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.Formations .bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.Formations .text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.Formations .w-8 {
  width: 2rem;
}

.Formations .h-8 {
  height: 2rem;
}

.Formations .mr-3 {
  margin-right: 0.75rem;
}

.Formations img,
.Formations svg,
.Formations video,
.Formations canvas,
.Formations audio,
.Formations iframe,
.Formations embed,
.Formations object {
  display: block;
  vertical-align: middle;
}

.Formations .rounded-lg {
  border-radius: 0.5rem;
}

.Formations .items-center {
  align-items: center;
}

.Formations .flex {
  display: flex;
}

.Formations .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.Formations .hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.Annuler:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
