/*
    
TemplateMo 557 Grad School

https://templatemo.com/tm-557-grad-school

*/

/* @import url(fontawesome.css); */
/* @import url(
    "<link href="https://fonts.googleapis.com/css?family=Montserrat:100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900" rel="stylesheet">"
  )
  ; body {
  font-family: "Montserrat", sans-serif;
} */

/* Basic */

a {
  color: #3cf;
  text-decoration: none;
}

a:hover {
  /* color: #fc3; */
  text-decoration: none;
}

ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

p {
  font-size: 13px;
  line-height: 22px;
}

.section-heading {
  text-align: center;
  margin-bottom: 70px;
}

.section-heading h2:before {
  width: 1px;
  height: 100px;
  background-color: rgba(250, 250, 250, 0.1);
  position: absolute;
  top: -102px;
  content: "";
  left: 50%;
  transform: translate(-50%);
}

.section-heading h2 {
  margin-top: 100px;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 15px 20px;
  border: 2px solid rgba(250, 250, 250, 0.1);
}

/* Buttons */

.main-button a {
  background-color: #f5a425;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding: 12px 20px;
  display: inline-block;
  outline: none;
}

a.menu-link {
  display: none;
}

@media screen and (max-width: 950px) {
  a.menu-link {
    float: right;
    display: block;
    font-size: 36px;
    color: #fff;
    padding-right: 30px;
    padding-top: 15px;
  }
  nav[role="navigation"] {
    clear: both;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .js nav[role="navigation"] {
    overflow: hidden;
    max-height: 0;
  }
  nav[role="navigation"].active {
    max-height: 20em;
    height: 20em;
    overflow-y: scroll;
  }
  nav[role="navigation"] ul {
    top: 0;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(250, 250, 250, 0.25);
    background-color: rgba(22, 34, 57, 0.99);
  }
  nav[role="navigation"] li a {
    display: block;
    padding: 15px 0px;
    border-bottom: 1px solid rgba(250, 250, 250, 0.25);
    text-align: center;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.5;
  }
  nav[role="navigation"] li:hover a,
  nav[role="navigation"] li.active a {
    color: #f5a425;
  }

  @media screen and (min-width: 951px) {
    .js nav[role="navigation"] {
      max-height: none;
    }

    nav[role="navigation"] li {
      display: inline-block;
      margin: 0 0.25em;
    }
    nav[role="navigation"] li a {
      border: 0;
    }
  }
}

/* Header */

@media screen and (max-width: 1050px) {
  .main-header .logo {
    padding-left: 30px !important;
  }

  .main-menu {
    padding-right: 30px !important;
  }

  .main-menu li {
    margin-left: 5px !important;
  }
}

.main-header {
  background-color: rgba(22, 34, 57, 0.95);
  height: 80px;
  position: fixed;
  z-index: 12;
  width: 100%;
  top: 0;
}

.main-header .logo {
  float: left;
  line-height: 80px;
  padding-left: 60px;
}

.main-header .logo a {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
}
.main-header .logo a em {
  font-style: normal;
  color: #f5a425;
}

.main-menu {
  float: right;
  padding-right: 60px;
}

.main-menu li {
  display: inline-block;
  line-height: 79px;
  margin-left: 15px;
  position: relative;
}

.main-menu li:first-child {
  margin-left: 0px;
}

.main-menu li a {
  padding: 10px 15px;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #fff;
  border: 2px solid transparent;
  transition: all 0.5s;
}

.main-menu li.has-submenu a:after {
  content: "\f107";
  font-family: "FontAwesome";
  margin-left: 5px;
}

.main-menu li.has-submenu ul li a:after {
  display: none;
}

.main-menu li .sub-menu {
  position: absolute;
  width: 160px;
  background-color: #18233a;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.main-menu li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
}

.main-menu li .sub-menu li {
  display: block;
  line-height: 20px;
  margin-left: 0px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #121b2f;
}

.main-menu li .sub-menu li:first-child {
  padding-top: 15px;
}

.main-menu li .sub-menu li:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}

.main-menu li .sub-menu li a {
  font-size: 12px;
  font-weight: 500;
  padding: 0px 15px;
  letter-spacing: 0.5px;
  border: none;
  transition: all 0.5s;
}

.main-menu li .sub-menu li a:hover {
  color: #f5a425;
  border: none;
}

.main-nav li:hover a,
.main-nav li.active a {
  border: 2px solid #f5a425;
}

@media (max-width: 950px) {
  .main-nav li:hover a,
  .main-nav li.active a {
    border: 2px solid transparent;
    border-bottom: 1px solid rgba(250, 250, 250, 0.25);
  }
}

/* Slider */

.main-banner {
  position: relative;
  max-height: 100%;
  overflow: hidden;
  margin-bottom: -7px;
}

#bg-video {
  min-width: 100%;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  z-index: -1;
}

#bg-video::-webkit-media-controls {
  display: none !important;
}

.video-overlay {
  position: absolute;
  background-color: rgba(22, 34, 57, 0.6);
  top: 0;
  left: 0;
  bottom: 7px;
  width: 100%;
  height: 100%;
}

.main-banner .caption {
  text-align: center;
  position: absolute;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.main-banner .caption h6 {
  margin-top: 0px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
}

.main-banner .caption h2 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 64px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
}

.main-banner .caption h2 em {
  font-style: normal;
  /* color: #f5a425; */
  font-weight: 900;
  --tw-text-opacity: 1;
  color: rgb(139 143 255 / var(--tw-text-opacity));
}

.main-banner .caption h6 {
  --tw-text-opacity: 1;
  color: rgb(139 143 255 / var(--tw-text-opacity));
}

@media screen and (max-width: 1180px) {
  .main-banner .caption h6 {
    font-weight: 500;
  }

  .main-banner .caption h2 {
    font-size: 58px;
  }
}
@media screen and (max-width: 767px) {
  .main-banner .caption h6 {
    font-weight: 500;
  }

  .main-banner .caption h2 {
    font-size: 36px;
  }
}

/* Features */

section.features .col-4 {
  padding-left: 0px;
  padding-right: 0px;
}

.features-post {
  position: relative;
  margin-bottom: 0px;
}

.features-thumb {
  overflow: hidden;
  position: relative;
}

.features-thumb img {
  width: 100%;
}

.features-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #0c1228;
  color: #fff;
  padding: 40px;
}

.features-content:hover {
  background-color: #f5a425;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
}

.features-content h4 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 0px;
}

.features-content h4 i {
  margin-right: 15px;
  font-size: 24px;
}

.features-content p {
  margin-bottom: 0px;
}

.features-content a {
  margin-top: 15px;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 14px;
  border-bottom: 2px solid #fff;
}

.features-content p.hidden-sm {
  display: none;
}

.second-features,
.third-features {
  border-left: 1px solid rgba(250, 250, 250, 0.1);
}

.content-hide {
  margin-top: 15px;
  display: none;
}

.content-hide p {
  color: #fff;
}

@media screen and (max-width: 767px) {
  .features-content {
    padding: 15px;
  }
  .features-content h4 i {
    display: block;
    margin-bottom: 10px;
  }
  .features-content h4 {
    font-size: 14px;
  }
  .features-content p {
    display: none;
  }
  .features-content p.hidden-sm {
    display: block;
  }
  .features-content a {
    letter-spacing: 0px;
    font-size: 13px;
    font-weight: 600;
  }
}

/* Why Choosing Us */

section.why-us {
  /* background-image: url(../images/choosing-bg.jpg); */
  background-color: #172238;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;
}

#tabs {
  text-align: center;
}
#tabs ul {
  margin: 0;
  padding: 0;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
  column-gap: 0;
}
#tabs ul::after {
  clear: both;
  content: "";
  display: table;
}
#tabs ul li {
  display: block;
  font-weight: 400;
  font-size: 1.2em;
  letter-spacing: 1px;
  text-align: center;
}
#tabs ul li a {
  display: block;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  outline: 0;
  padding-bottom: 30px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}
#tabs ul li a:after {
  transition: all 0.3s;
  width: 10px;
  height: 10px;
  background-color: #fff;
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
}
#tabs ul li a:before {
  transition: all 0.3s;
  width: 25px;
  height: 25px;
  border: 2px solid transparent;
  background-color: transparent;
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -5px;
}
#tabs ul li span {
  display: block;
  margin-bottom: 0.75em;
}
#tabs ul .ui-tabs-active {
}
#tabs ul .ui-tabs-active a {
  color: #f5a425;
}
#tabs ul .ui-tabs-active a:after {
  background-color: #f5a425;
  width: 15px;
  height: 15px;
}
#tabs ul .ui-tabs-active a:before {
  border-color: #f5a425;
}
#tabs h4 {
  margin-top: 60px;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 30px;
}

#tabs p {
  color: #fff;
  font-size: 15px;
  line-height: 28px;
}

.tabs-content {
  margin-top: 60px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .tabs-content {
    text-align: center;
  }
}

.tabs-content img {
  width: 100%;
  overflow: hidden;
  padding-right: 45px;
}

@media screen and (max-width: 767px) {
  .tabs-content img {
    padding-right: 0px;
  }
}

/* Coming Soon */

section.coming-soon {
  /* background-image: url(../images/coming-soon-bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #172238;
  padding: 120px 0px;
}

section.coming-soon .continer .counter div {
  display: inline-block;
}
section.coming-soon .continer h4 {
  margin-top: 100px;
  font-size: 30px;
  color: #fff;
  font-weight: 800;
  letter-spacing: 0.5px;
  line-height: 40px;
  margin-bottom: 50px;
}
section.coming-soon .continer h4 em {
  font-style: normal;
  color: #f5a425;
}

@media screen and (max-width: 767px) {
  section.coming-soon .continer h4 {
    text-align: center;
  }
}

section.coming-soon .continer .counter .days,
section.coming-soon .continer .counter .hours,
section.coming-soon .continer .counter .minutes,
section.coming-soon .continer .counter .seconds {
  width: 22%;
  height: 140px;
  float: left;
  text-align: center;
  font-size: 48px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #fff;
  background-color: rgba(250, 250, 250, 0.1);
}
@media screen and (max-width: 992px) {
  section.coming-soon .continer .counter .days,
  section.coming-soon .continer .counter .hours,
  section.coming-soon .continer .counter .minutes,
  section.coming-soon .continer .counter .seconds {
    font-size: 26px;
  }

  section.coming-soon .continer .counter .days .value,
  section.coming-soon .continer .counter .hours .value,
  section.coming-soon .continer .counter .minutes .value,
  section.coming-soon .continer .counter .seconds .value {
    margin-top: 40px !important;
  }

  section.coming-soon .continer .counter span {
    font-size: 14px !important;
  }
}
section.coming-soon .continer .counter .days,
section.coming-soon .continer .counter .hours,
section.coming-soon .continer .counter .minutes {
  margin-right: 4%;
}
section.coming-soon .continer .counter .days .value,
section.coming-soon .continer .counter .hours .value,
section.coming-soon .continer .counter .minutes .value,
section.coming-soon .continer .counter .seconds .value {
  margin-top: 15px;
  display: block;
  width: 100%;
}

section.coming-soon .continer .counter span {
  font-size: 18px;
  text-transform: uppercase;
  color: #f5a425;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 0px;
  display: block;
}

section.coming-soon .right-content {
  margin-left: 30px;
}

@media screen and (max-width: 767px) {
  section.coming-soon .right-content {
    margin-top: 60px;
    margin-left: 0px;
  }
}

section.coming-soon .top-content {
  margin-bottom: -8px;
}

section.coming-soon .top-content h6 {
  font-size: 14px;
  color: #fff;
  background-color: rgba(250, 250, 250, 0.2);
  padding: 40px;
  text-align: center;
  line-height: 24px;
}

section.coming-soon .top-content h6 em {
  font-style: normal;
  font-weight: 600;
}

section.coming-soon form {
  background-color: rgba(250, 250, 250, 0.1);
  padding: 60px 40px;
  width: 100%;
  text-align: center;
}

section.coming-soon form input {
  width: 100%;
  height: 40px;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 0px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 13px;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
}

section.coming-soon form input:focus {
  outline: none;
  border: none;
  box-shadow: none;
  color: #fff;
  background-color: rgba(250, 250, 250, 0.1);
}

section.coming-soon form::placeholder {
  color: #fff;
}
section.coming-soon form input::placeholder {
  color: #fff;
}
section.coming-soon form input::placeholder {
  color: #fff;
}

section.coming-soon form button {
  margin-top: 10px;
  background-color: #f5a425;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding: 12px 20px;
  display: inline-block;
  outline: none;
  box-shadow: none;
  border: none;
}

/* Courses */

section.courses {
  /* background-image: url(../images/choosing-bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #172238;
  padding-bottom: 100px;
  padding: 0px 30px 100px 30px;
}

section.courses .item img {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

section.courses .item .down-content {
  padding: 30px;
  background-color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

section.courses .item .down-content h4 {
  font-size: 16px;
  text-transform: uppercase;
  color: #1e1e1e;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;
}

section.courses .item .down-content p {
  margin-bottom: 25px;
}

section.courses .item .down-content img {
  width: 40px;
  border: 2px solid #f5a425;
  border-radius: 50%;
  text-align: left;
  display: inline-block;
}

section.courses .item .down-content .text-button-pay {
  float: right;
  display: inline-block;
  margin-top: -30px;
}

section.courses .item .down-content .text-button-pay a {
  color: #f5a425;
  font-size: 13px;
}

section.courses .item .down-content .text-button-free {
  float: right;
  display: inline-block;
  margin-top: -30px;
}

section.courses .item .down-content .text-button-free a {
  color: #7a7a7a;
  font-size: 13px;
}

section.courses .owl-carousel .owl-nav {
  display: none;
}

section.courses .owl-carousel .owl-dots {
  text-align: center;
}

section.courses .owl-carousel button.owl-dot {
  width: 10px;
  height: 10px;
  background-color: #fff;
  margin: 50px 10px 0px 10px;
  outline: none;
}
section.courses .owl-carousel button.active {
  background-color: #f5a425;
}

/* Video */

section.video {
  /* background-image: url(../images/video-bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #172238;
  padding: 120px 0px;
}

section.video .left-content {
  color: #fff;
}

section.video .left-content span {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
}

section.video .left-content h4 {
  margin-top: 20px;
  font-size: 30px;
  color: #fff;
  font-weight: 800;
  letter-spacing: 0.5px;
  line-height: 40px;
  margin-bottom: 20px;
}
section.video .left-content h4 em {
  font-style: normal;
  color: #f5a425;
}

section.video .left-content .main-button {
  margin-top: 30px;
}

.video-item {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0px auto 0;
}

@media screen and (max-width: 767px) {
  section.video .left-content {
    margin-bottom: 45px;
  }

  section.video .first-item .first-content h4,
  section.video .second-item .second-content h4 {
    text-align: center;
  }
}

.video-item figure {
  position: relative;
  width: 100%;
  font-size: 0;
}
.video-item figure img {
  width: 100%;
}
.video-item figure a:before {
  content: "";
  position: absolute;
  bottom: 10px;
  right: 15px;
  width: 60px;
  height: 60px;
  margin-top: -32.5px;
  margin-left: -32.5px;
  border-radius: 50%;
  background-color: #f5a425;
  z-index: 10;
}
.video-item figure a:after {
  content: "";
  position: absolute;
  bottom: 27.5px;
  right: 20px;
  margin-top: -12.5px;
  margin-left: -7px;
  border: solid 13px transparent;
  border-left: solid 20px;
  border-left-color: #fff;
  z-index: 10;
}
.video-item figure a:hover:before {
  background-color: #f5a425;
}

.video-item .video-caption {
  position: absolute;
  z-index: 10;
  background-color: rgba(250, 250, 250, 0.75);
  height: 80px;
  width: 100%;
  padding: 27px 30px;
  bottom: 0;
}

.video-item .video-caption h4 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/* Contact */

section.contact {
  /* background-image: url(../images/contact-bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #172238;
  padding-bottom: 120px;
}

section.contact form {
  background-color: rgba(250, 250, 250, 0.1);
  padding: 30px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  section.contact form {
    margin-bottom: 30px;
  }
}

section.contact form input {
  width: 100%;
  height: 40px;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 0px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 13px;
  margin-bottom: 30px;
  letter-spacing: 0.5px;
}

section.contact form textarea {
  width: 100%;
  height: 220px;
  max-height: 280px;
  min-height: 220px;
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 0px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 13px;
  margin-bottom: 30px;
  letter-spacing: 0.5px;
}

section.contact form input:focus,
section.contact form textarea:focus {
  outline: none;
  border: none;
  box-shadow: none;
  color: #fff;
  background-color: rgba(250, 250, 250, 0.1);
}

section.contact form::placeholder {
  color: #fff;
}
section.contact form input::placeholder {
  color: #fff;
}
section.contact form input::placeholder {
  color: #fff;
}

section.contact form::placeholder {
  color: #fff;
}
section.contact form textarea::placeholder {
  color: #fff;
}
section.contact form textarea::placeholder {
  color: #fff;
}

section.contact form button {
  background-color: #f5a425;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding: 12px 20px;
  display: inline-block;
  outline: none;
  box-shadow: none;
  border: none;
}

/* Footer */

footer {
  background-color: #152036;
  text-align: center;
}

footer p {
  margin-bottom: 0px;
  padding: 25px 0px;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
}

footer p a,
footer p a:hover {
  color: #f5a425;
}

@media screen and (max-width: 992px) {
  .features {
    background-color: #0c1228;
  }
  .features-content {
    position: relative;
  }
  .features-post {
    border-left: 0;
  }
}

@media screen and (max-width: 950px) {
  .main-menu {
    padding-right: 0 !important;
  }
  .main-header .logo {
    line-height: 73px;
  }
  .main-menu li {
    display: block;
    line-height: 1;
    margin-left: 0 !important;
  }

  .main-menu li .sub-menu {
    opacity: 1;
    visibility: visible;
    position: static;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .main-menu li .sub-menu li:last-child {
    border-bottom: 1px solid rgba(250, 250, 250, 0.25);
  }
}

@media screen and (max-width: 950px) and (max-height: 400px) {
  nav[role="navigation"].active {
    max-height: calc(80px + 100vh);
    overflow-y: auto;
  }
}

@media screen and (max-width: 767px) {
  footer p {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.25px;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-1xomo8h-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  left: 151px;
  width: 78%;
}

.sidebar-textwidget {
  padding: 13px 18px;
}

#social {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 36px;
  text-align: center;
  background: #4026e1;
  color: #fff;
  text-decoration: none;
  transition: 0.5s;
  font-size: 18px;
  border-radius: 3px;
}
