:root {
  --tw-translate-y: 0;
  --tw-translate-x: 0;
}

.E_Trainings_courses .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.E_Trainings_courses .py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.E_Trainings_courses .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.E_Trainings_courses .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.E_Trainings_courses .text-center {
  text-align: center;
}

.E_Trainings_courses .max-w-6xl {
  max-width: 72rem;
}

.E_Trainings_courses .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.E_Trainings_courses .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.E_Trainings_courses .mb-4 {
  margin-bottom: 1rem;
}

.E_Trainings_courses blockquote,
.E_Trainings_courses dl,
.E_Trainings_courses dd,
.E_Trainings_courses h1,
.E_Trainings_courses h2,
.E_Trainings_courses h3,
.E_Trainings_courses h4,
.E_Trainings_courses h5,
.E_Trainings_courses h6,
.E_Trainings_courses hr,
.E_Trainings_courses figure,
.E_Trainings_courses p,
.E_Trainings_courses pre {
  margin: 0;
}

.E_Trainings_courses h1,
.E_Trainings_courses h2,
.E_Trainings_courses h3,
.E_Trainings_courses h4,
.E_Trainings_courses h5,
.E_Trainings_courses h6 {
  font-size: inherit;
  font-weight: inherit;
}

.E_Trainings_courses .text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.E_Trainings_courses .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.E_Trainings_courses .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.E_Trainings_courses .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.E_Trainings_courses .max-w-6xl {
  max-width: 72rem;
}

.E_Trainings_courses .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.E_Trainings_courses .font-bold {
  font-weight: 700;
}

.E_Trainings_courses .gap-4 {
  gap: 1rem;
}

.E_Trainings_courses .justify-center {
  justify-content: center;
}

.E_Trainings_courses .flex-wrap {
  flex-wrap: wrap;
}

.E_Trainings_courses .mb-12 {
  margin-bottom: 3rem;
}

.E_Trainings_courses .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.E_Trainings_courses .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.E_Trainings_courses .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.E_Trainings_courses .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.E_Trainings_courses .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.E_Trainings_courses .rounded-full {
  border-radius: 9999px;
}

.E_Trainings_courses button,
[role="button"] {
  cursor: pointer;
}

.E_Trainings_courses button,
.E_Trainings_courses input:where([type="button"]),
.E_Trainings_courses input:where([type="reset"]),
.E_Trainings_courses input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.E_Trainings_courses button,
.E_Trainings_courses select {
  text-transform: none;
}

.E_Trainings_courses button,
.E_Trainings_courses input,
.E_Trainings_courses optgroup,
.E_Trainings_courses select,
.E_Trainings_courses textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

@media (min-width: 1024px) {
  .E_Trainings_courses .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .E_Trainings_courses .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.E_Trainings_courses .gap-8 {
  gap: 2rem;
}

.E_Trainings_courses .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.E_Trainings_courses .grid {
  display: grid;
}

.E_Trainings_courses .transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.E_Trainings_courses .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.E_Trainings_courses .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.E_Trainings_courses .rounded-xl {
  border-radius: 0.75rem;
}

.E_Trainings_courses .overflow-hidden {
  overflow: hidden;
}

.E_Trainings_courses .hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.E_Trainings_courses .active {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.E_Trainings_courses .h-48 {
  height: 12rem;
}

.E_Trainings_courses .relative {
  position: relative;
}

.E_Trainings_courses .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.E_Trainings_courses .w-full {
  width: 100%;
}

.E_Trainings_courses .h-full {
  height: 100%;
}

.E_Trainings_courses img,
video {
  max-width: 100%;
  height: auto;
}

.E_Trainings_courses img,
.E_Trainings_courses svg,
.E_Trainings_courses video,
.E_Trainings_courses canvas,
.E_Trainings_courses audio,
.E_Trainings_courses iframe,
.E_Trainings_courses embed,
.E_Trainings_courses object {
  display: block;
  vertical-align: middle;
}

.E_Trainings_courses .p-6 {
  padding: 1.5rem;
}

.E_Trainings_courses .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.E_Trainings_courses .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.E_Trainings_courses .mb-2 {
  margin-bottom: 0.5rem;
}

.E_Trainings_courses .font-bold {
  font-weight: 700;
}

.E_Trainings_courses .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.E_Trainings_courses .mb-4 {
  margin-bottom: 1rem;
}

.E_Trainings_courses blockquote,
.E_Trainings_courses dl,
.E_Trainings_courses dd,
.E_Trainings_courses h1,
.E_Trainings_courses h2,
.E_Trainings_courses h3,
.E_Trainings_courses h4,
.E_Trainings_courses h5,
.E_Trainings_courses h6,
.E_Trainings_courses hr,
.E_Trainings_courses figure,
.E_Trainings_courses p,
.E_Trainings_courses pre {
  margin: 0;
}

.E_Trainings_courses h1,
.E_Trainings_courses h2,
.E_Trainings_courses h3,
.E_Trainings_courses h4,
.E_Trainings_courses h5,
.E_Trainings_courses h6 {
  font-size: inherit;
  font-weight: inherit;
}

.E_Trainings_courses .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.E_Trainings_courses .gap-2 {
  gap: 0.5rem;
}

.E_Trainings_courses .items-center {
  align-items: center;
}

.E_Trainings_courses .mb-2 {
  margin-bottom: 0.5rem;
}

.E_Trainings_courses .w-4 {
  width: 1rem;
}

.E_Trainings_courses .h-4 {
  height: 1rem;
}

.E_Trainings_courses img,
.E_Trainings_courses svg,
.E_Trainings_courses video,
.E_Trainings_courses canvas,
.E_Trainings_courses audio,
.E_Trainings_courses iframe,
.E_Trainings_courses embed,
.E_Trainings_courses object {
  display: block;
  vertical-align: middle;
}

.E_Trainings_courses .justify-between {
  justify-content: space-between;
}

.E_Trainings_courses .flex {
  display: flex;
}

.E_Trainings_courses .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.E_Trainings_courses .gap-1 {
  gap: 0.25rem;
}

.E_Trainings_courses .items-center {
  align-items: center;
}

.E_Trainings_courses .inline-flex {
  display: inline-flex;
}

.E_Trainings_courses .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.E_Trainings_courses .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.E_Trainings_courses .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.E_Trainings_courses .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.E_Trainings_courses .rounded-lg {
  border-radius: 0.5rem;
}

.E_Trainings_courses .gap-2 {
  gap: 0.5rem;
}

.E_Trainings_courses .items-center {
  align-items: center;
}

.E_Trainings_courses .flex {
  display: flex;
}

.E_Trainings_courses button,
[role="button"] {
  cursor: pointer;
}

.E_Trainings_courses button,
.E_Trainings_courses select {
  text-transform: none;
}

.E_Trainings_courses .hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.E_Trainings_courses .py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.E_Trainings_courses .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.E_Trainings_courses .bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.E_Trainings_courses .top-4 {
  top: 1rem;
}

.E_Trainings_courses .right-4 {
  right: 1rem;
}

.E_Trainings_courses .absolute {
  position: absolute;
}

.E_Trainings_courses .hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
