.E_Trainings_Course_groups {
  background: #f5f6fa;
  color: #2c3e50;
  line-height: 1.6;
}

.E_Trainings_Course_groups .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.E_Trainings_Course_groups .header-section {
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
  padding-bottom: 2rem;
}

.E_Trainings_Course_groups .header-section h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.E_Trainings_Course_groups .header-section h1::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: #f1c40f;
}

.E_Trainings_Course_groups .header-section p {
  font-size: 1.1rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
}

.E_Trainings_Course_groups .cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;
  margin-bottom: 4rem;
}

.E_Trainings_Course_groups .service-card {
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

.E_Trainings_Course_groups .service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.E_Trainings_Course_groups .card-header {
  background: linear-gradient(135deg, #2c3e50 0%, #3498db 100%);
  padding: 1.5rem;
  color: #ffffff;
}

.E_Trainings_Course_groups .card-header.confirmation {
  background: linear-gradient(135deg, #27ae60 0%, #2ecc71 100%);
}

.E_Trainings_Course_groups .card-content {
  padding: 2rem;
}

.E_Trainings_Course_groups .card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.E_Trainings_Course_groups .card-description {
  margin-bottom: 2rem;
  color: #666;
  font-size: 1.1rem;
}

.E_Trainings_Course_groups .contact-button {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  background: #2c3e50;
  color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.E_Trainings_Course_groups .contact-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.E_Trainings_Course_groups .contact-button.confirmation {
  background: #27ae60;
}

.E_Trainings_Course_groups .whatsapp-icon {
  font-size: 1.5rem;
}

.E_Trainings_Course_groups .status-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #ffffff;
}

@media (max-width: 768px) {
  .E_Trainings_Course_groups .container {
    padding: 2rem 1rem;
  }

  .E_Trainings_Course_groups .header-section h1 {
    font-size: 2rem;
  }

  .E_Trainings_Course_groups .cards-container {
    grid-template-columns: 1fr;
  }
}
