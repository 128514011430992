:root {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-bg-opacity: 1;
  --tw-gradient-from: #000;
  --tw-gradient-to: #fff;
}

.services-section {
  position: relative;
  z-index: 5;
}

@media (min-width: 1024px) {
  .services-section .lg\:gap-8 {
    gap: 2rem;
  }
}

@media (min-width: 768px) {
  .services-section .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.services-section .gap-6 {
  gap: 1.5rem;
}

.services-section .grid {
  display: grid;
}

.services-section .duration-300 {
  transition-duration: 300ms;
}

.services-section .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.services-section .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.services-section .p-8 {
  padding: 2rem;
}

.services-section .bg-navy-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 34 53 / var(--tw-bg-opacity));
}

.services-section .rounded-2xl {
  border-radius: 1rem;
}

.services-section .mb-6 {
  margin-bottom: 1.5rem;
}

.services-section .text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(139 143 255 / var(--tw-text-opacity));
}

.services-section .w-12 {
  width: 3rem;
}

.services-section .h-12 {
  height: 3rem;
}

.services-section img,
.services-section svg,
.services-section video,
.services-section canvas,
.services-section audio,
.services-section iframe,
.services-section embed,
.services-section object {
  display: block;
  vertical-align: middle;
}

.services-section .font-semibold {
  font-weight: 600;
}

.services-section .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.services-section .font-heading {
  font-family: Clash Display, sans-serif;
}

.services-section .mb-4 {
  margin-bottom: 1rem;
}

.services-section .text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.services-section .leading-relaxed {
  line-height: 1.625;
}

.services-section .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.services-section .mb-8 {
  margin-bottom: 2rem;
}

.services-section blockquote,
.services-section dl,
.services-section dd,
.services-section h1,
.services-section h2,
.services-section h3,
.services-section h4,
.services-section h5,
.services-section h6,
.services-section hr,
.services-section figure,
.services-section p,
.services-section pre {
  margin: 0;
}

.services-section .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.services-section .text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(139 143 255 / var(--tw-text-opacity));
}

.services-section .font-semibold {
  font-weight: 600;
}

.services-section .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.services-section .font-heading {
  font-family: Clash Display, sans-serif;
}

.services-section .items-center {
  align-items: center;
}

.services-section .inline-flex {
  display: inline-flex;
}

.services-section button,
.services-section [role="button"] {
  cursor: pointer;
}

.services-section button,
.services-section input:where([type="button"]),
.services-section input:where([type="reset"]),
.services-section input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.services-section button,
.services-section select {
  text-transform: none;
}

.services-section button,
.services-section input,
.services-section optgroup,
.services-section select,
.services-section textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.services-section .hover\:scale-\[1\.02\]:hover {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.services-section .bg-primary-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(93 74 247 / var(--tw-bg-opacity));
}

.services-section .to-primary-900\/80 {
  background: linear-gradient(
    90deg,
    rgb(17 22 39 / 1),
    rgb(32 38 62 / 1),
    rgb(56 47 137 / 1)
  );
}
