.Sidebar.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Sidebar.to-blue-600 {
  background: linear-gradient(to bottom, #2563eb, #3b82f6);
}

.Sidebar.w-64 {
  width: 16rem;
}

.Sidebar.h-full {
  height: 100%;
}

.Sidebar.top-0 {
  top: 0px;
}

.Sidebar.left-0 {
  left: 0px;
}

.Sidebar .p-6 {
  padding: 1.5rem;
}

.Sidebar .mt-6 {
  margin-top: 1.5rem;
}

.Sidebar .font-semibold {
  font-weight: 600;
}

.Sidebar .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.Sidebar blockquote,
.Sidebar dl,
.Sidebar dd,
.Sidebar h1,
.Sidebar h2,
.Sidebar h3,
.Sidebar h4,
.Sidebar h5,
.Sidebar h6,
.Sidebar hr,
.Sidebar figure,
.Sidebar p,
.Sidebar pre {
  margin: 0;
}

.Sidebar h1,
.Sidebar h2,
.Sidebar h3,
.Sidebar h4,
.Sidebar h5,
.Sidebar h6 {
  font-size: inherit;
  font-weight: inherit;
}

.Sidebar .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Sidebar .font-medium {
  font-weight: 500;
}

.Sidebar .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Sidebar .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.Sidebar .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.Sidebar .border-transparent {
  border-color: transparent;
}

.Sidebar .border-l-4 {
  border-left-width: 4px;
}

.Sidebar .items-center {
  align-items: center;
}

.Sidebar .flex {
  display: flex;
}

.Sidebar a {
  color: inherit;
  text-decoration: inherit;
}

.Sidebar .bg-blue-400\/20 {
  background-color: rgb(96 165 250 / 0.2);
}

.Sidebar .border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.Sidebar .hover\:bg-blue-400\/20:hover {
  background-color: rgb(96 165 250 / 0.2);
}

.Sidebar .w-5 {
  width: 1.25rem;
}

.Sidebar .h-5 {
  height: 1.25rem;
}

.Sidebar .mr-3 {
  margin-right: 0.75rem;
}

.Sidebar img,
.Sidebar svg,
.Sidebar video,
.Sidebar canvas,
.Sidebar audio,
.Sidebar iframe,
.Sidebar embed,
.Sidebar object {
  display: block;
  vertical-align: middle;
}
