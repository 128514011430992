:root {
  /* --primary-color: #2c3e50; */
  /* --secondary-color: #3498db; */
  /* --background-color: #f5f7fa; */
  /* --card-bg: #ffffff; */
  /* --text-color: #2d3436; */
  /* --border-radius: 10px; */
  /* --shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  --transition: all 0.3s ease;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.Course-Checkout {
  background: #f5f7fa;
  color: #2d3436;
  min-height: 100vh;
  padding: 20px;
  line-height: 1.6;
}

.Course-Checkout .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.Course-Checkout .page-header {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px;
  background: #2c3e50;
  color: white;
  border-radius: 10px;
}

.Course-Checkout .page-header h1,
.Course-Checkout .page-header p {
  color: white;
}

.Course-Checkout .page-header h1 {
  font-size: 30px;
}

.Course-Checkout .checkout-steps {
  display: flex;
  margin-bottom: 30px;
  position: relative;
  padding: 20px 0;
}

.Course-Checkout .step {
  flex: 1;
  padding: 15px 20px;
  background-color: #2f3640;
  color: white;
  position: relative;
  font-size: 16px;
  transition: all 0.3s ease;
}

.Course-Checkout .step:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -20px;
  top: 0;
  width: 40px;
  height: 100%;
  background-color: inherit;
  clip-path: polygon(0 0, 50% 0, 100% 50%, 50% 100%, 0 100%);
  z-index: 1;
}

.Course-Checkout .step.active {
  background-color: #3498db;
}

.Course-Checkout .checkout-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 30px;
}

@media (max-width: 968px) {
  .Course-Checkout .checkout-container {
    grid-template-columns: 1fr;
  }
}

.Course-Checkout .checkout-form {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.Course-Checkout .form-section {
  margin-bottom: 25px;
}

.Course-Checkout .form-section-title {
  font-size: 1.2em;
  color: #2c3e50;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #3498db;
}

.Course-Checkout .form-group {
  margin-bottom: 20px;
}

.Course-Checkout .form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #2d3436;
}

.Course-Checkout .form-control {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.Course-Checkout .form-control:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  outline: none;
}

.Course-Checkout .payment-methods {
  display: grid;
  gap: 20px;
}

.Course-Checkout .payment-method {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.Course-Checkout .payment-method:hover {
  border-color: #3498db;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.Course-Checkout .payment-option.selected,
.Course-Checkout .payment-method.selected {
  border-color: #3498db;
  background-color: #f8f9ff;
}

.Course-Checkout .payment-method-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Course-Checkout .payment-method-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border-radius: 8px;
  color: #3498db;
}

.Course-Checkout .payment-method-title {
  font-weight: 600;
  font-size: 1.1em;
  color: #2d3436;
}

.Course-Checkout .payment-method-description {
  color: #636e72;
  font-size: 0.9em;
  line-height: 1.5;
}

.Course-Checkout .payment-method-radio {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
}

.Course-Checkout .payment-providers {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.Course-Checkout .provider-badge {
  background: #f1f2f6;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.85em;
  color: #2d3436;
}

.Course-Checkout .btn {
  background: #3498db;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: 20px;
}

.Course-Checkout .btn:hover {
  background: #2980b9;
  transform: translateY(-2px);
}

.Course-Checkout .order-summary {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 20px;
}

.Course-Checkout .summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.Course-Checkout .summary-total {
  font-size: 1.2em;
  font-weight: bold;
  color: #2c3e50;
}

.Course-Checkout .secure-badge {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 10px;
}

.Course-Checkout .secure-badge i {
  color: #27ae60;
  font-size: 1.2em;
}

[dir='rtl'] {
  text-align: right;
}

[dir='rtl'] .Course-Checkout .payment-method-icon {
  margin-right: 0;
  margin-left: 15px;
}

[dir='rtl'] .Course-Checkout .payment-method-radio {
  right: auto;
  left: 20px;
}

@media (max-width: 768px) {
  .Course-Checkout .checkout-steps {
    flex-direction: column;
  }

  .Course-Checkout .step:not(:last-child)::after {
    display: none;
  }

  .Course-Checkout .payment-methods {
    grid-template-columns: 1fr;
  }
}

.Course-Checkout .payment-info {
  padding: 15px;
  background-color: #e8f0fe;
  border-radius: 8px;
}

.Course-Checkout .payment-info h3,
.Course-Checkout .payment-info p {
  font-size: 14px;
  color: #1a73e8;
}
/* -------------------------------------------------------------------------- */
.Course-Checkout .payment-options {
  display: grid;
  gap: 15px;
  margin: 20px 0;
}

.Course-Checkout .payment-option {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #fff;
}

.Course-Checkout .payment-option:hover {
  background-color: #f8f9fa;
  border-color: #007bff;
}

.Course-Checkout .payment-option input[type='radio'] {
  margin-right: 12px;
  accent-color: #007bff;
  width: 4%;
}

.Course-Checkout .payment-option span {
  flex-grow: 1;
  font-size: 16px;
  color: #212529;
}

.Course-Checkout .info-icon {
  margin-left: auto;
  color: #6c757d;
  cursor: help;
  transition: color 0.3s ease;
}

.Course-Checkout .ininfo-icon:hover {
  color: #007bff;
}
/* --------------------------------------------------------------------- */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

select,
input[type='text'],
input[type='date'] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.react-datepicker-wrapper {
  width: 100%;
}
