.DiminuirPpartenir .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.DiminuirPpartenir .p-6 {
  padding: 1.5rem;
}

.DiminuirPpartenir .rounded-lg {
  border-radius: 0.5rem;
}

.DiminuirPpartenir .mb-6 {
  margin-bottom: 1.5rem;
}

.DiminuirPpartenir .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.DiminuirPpartenir .font-medium {
  font-weight: 500;
}

.DiminuirPpartenir .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.DiminuirPpartenir .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.DiminuirPpartenir .px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.DiminuirPpartenir button,
.DiminuirPpartenir input,
.DiminuirPpartenir optgroup,
.DiminuirPpartenir select,
.DiminuirPpartenir textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.DiminuirPpartenir button,
select {
  text-transform: none;
}

.DiminuirPpartenir button,
.DiminuirPpartenir input:where([type='button']),
.DiminuirPpartenir input:where([type='reset']),
.DiminuirPpartenir input:where([type='submit']) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.DiminuirPpartenir button,
[role='button'] {
  cursor: pointer;
}

.DiminuirPpartenir .whitespace-nowrap {
  white-space: nowrap;
}

.DiminuirPpartenir .border-b-2 {
  border-bottom-width: 2px;
}

.DiminuirPpartenir .border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.DiminuirPpartenir .px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.DiminuirPpartenir .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.DiminuirPpartenir .font-medium {
  font-weight: 500;
}

.DiminuirPpartenir .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.DiminuirPpartenir .space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.DiminuirPpartenir .hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.DiminuirPpartenir .hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.DiminuirPpartenir .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.DiminuirPpartenir .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.DiminuirPpartenir .px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.DiminuirPpartenir .border-transparent {
  border-color: transparent;
}

.DiminuirPpartenir .border-b-2 {
  border-bottom-width: 2px;
}

.DiminuirPpartenir .whitespace-nowrap {
  white-space: nowrap;
}

.DiminuirPpartenir .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.DiminuirPpartenir .tracking-wider {
  letter-spacing: 0.05em;
}

.DiminuirPpartenir .uppercase {
  text-transform: uppercase;
}

.DiminuirPpartenir .font-medium {
  font-weight: 500;
}

.DiminuirPpartenir .text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.DiminuirPpartenir .text-left {
  text-align: left;
}

.DiminuirPpartenir .w-5 {
  width: 1.25rem;
}

.DiminuirPpartenir .h-5 {
  height: 1.25rem;
}

.DiminuirPpartenir .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.DiminuirPpartenir .min-w-full {
  min-width: 100%;
}

.DiminuirPpartenir table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

.DiminuirPpartenir .overflow-hidden {
  overflow: hidden;
}

.DiminuirPpartenir .overflow-x-auto {
  overflow-x: auto;
}

.DiminuirPpartenir .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.DiminuirPpartenir .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.DiminuirPpartenir .gap-4 {
  gap: 1rem;
}

.DiminuirPpartenir .grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.DiminuirPpartenir .grid {
  display: grid;
}

.DiminuirPpartenir .p-2\.5 {
  padding: 0.625rem;
}

.DiminuirPpartenir .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.DiminuirPpartenir .border {
  border-width: 1px;
}

.DiminuirPpartenir button,
.DiminuirPpartenir select {
  text-transform: none;
}

.DiminuirPpartenir .hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.DiminuirPpartenir .font-semibold {
  font-weight: 600;
}

.DiminuirPpartenir .leading-5 {
  line-height: 1.25rem;
}

.DiminuirPpartenir .bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

.DiminuirPpartenir .bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.DiminuirPpartenir .bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.ClientForm .text-red-600,
.DiminuirPpartenir .text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.DiminuirPpartenir .text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.DiminuirPpartenir .hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

.DiminuirPpartenir .cursor-not-allowed {
  cursor: not-allowed;
}

.DiminuirPpartenir .text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.DiminuirPpartenir .hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity));
}

.DiminuirPpartenir .text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
}

.DiminuirPpartenir .text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.DiminuirPpartenir .hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.DiminuirPpartenir .font-semibold {
  font-weight: 600;
}

.DiminuirPpartenir .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .max-w-md {
  max-width: 28rem;
}

.DiminuirPpartenir .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.DiminuirPpartenir .hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
