.Formation_Dinitiation_Course .to-white {
  /* --tw-gradient-to: #fff var(--tw-gradient-to-position); */
  background: linear-gradient(90deg, #f0f9ff, #fff);
}

.Formation_Dinitiation_Course .min-h-screen {
  min-height: 100vh;
}

.Formation_Dinitiation_Course .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Formation_Dinitiation_Course .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.Formation_Dinitiation_Course .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.Formation_Dinitiation_Course .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Formation_Dinitiation_Course .max-w-7xl {
  max-width: 80rem;
}

.Formation_Dinitiation_Course .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.Formation_Dinitiation_Course .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.Formation_Dinitiation_Course .font-semibold {
  font-weight: 600 !important;
}

.Formation_Dinitiation_Course .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.Formation_Dinitiation_Course .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.Formation_Dinitiation_Course .mb-12 {
  margin-bottom: 3rem;
}

.Formation_Dinitiation_Course .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.Formation_Dinitiation_Course .mb-6 {
  margin-bottom: 1.5rem;
}

.Formation_Dinitiation_Course .gap-6 {
  gap: 1.5rem;
}

.Formation_Dinitiation_Course .grid {
  display: grid;
}

.Formation_Dinitiation_Course .transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Formation_Dinitiation_Course .shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Formation_Dinitiation_Course .rounded-lg {
  border-radius: 0.5rem;
}

.Formation_Dinitiation_Course .overflow-hidden {
  overflow: hidden;
}

.Formation_Dinitiation_Course .h-48 {
  height: 12rem;
}

.Formation_Dinitiation_Course .relative {
  position: relative;
}

.Formation_Dinitiation_Course .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.Formation_Dinitiation_Course .w-full {
  width: 100%;
}

.Formation_Dinitiation_Course .h-full {
  height: 100%;
}

.Formation_Dinitiation_Course img,
.Formation_Dinitiation_Course video {
  max-width: 100%;
  height: auto;
}

.Formation_Dinitiation_Course .p-4 {
  padding: 1rem;
}

.Formation_Dinitiation_Course .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.Formation_Dinitiation_Course .mb-2 {
  margin-bottom: 0.5rem;
}

.Formation_Dinitiation_Course .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.Formation_Dinitiation_Course .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Formation_Dinitiation_Course .mb-4 {
  margin-bottom: 1rem;
}

.Formation_Dinitiation_Course .text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.Formation_Dinitiation_Course .items-center {
  align-items: center;
}

.Formation_Dinitiation_Course .flex {
  display: flex;
}

.Formation_Dinitiation_Course .w-4 {
  width: 1rem;
}

.Formation_Dinitiation_Course .h-4 {
  height: 1rem;
}

.Formation_Dinitiation_Course .mr-2 {
  margin-right: 0.5rem;
}

.Formation_Dinitiation_Course img,
.Formation_Dinitiation_Course svg,
.Formation_Dinitiation_Course video,
.Formation_Dinitiation_Course canvas,
.Formation_Dinitiation_Course audio,
.Formation_Dinitiation_Course iframe,
.Formation_Dinitiation_Course embed,
.Formation_Dinitiation_Course object {
  display: block;
  vertical-align: middle;
}

.Formation_Dinitiation_Course .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Formation_Dinitiation_Course .text-center {
  text-align: center;
}

.Formation_Dinitiation_Course .p-3 {
  padding: 0.75rem;
}

.Formation_Dinitiation_Course .bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.Formation_Dinitiation_Course .rounded-lg {
  border-radius: 0.5rem;
}

.Formation_Dinitiation_Course .flex-shrink-0 {
  flex-shrink: 0;
}

.Formation_Dinitiation_Course .w-20 {
  width: 5rem;
}

.Formation_Dinitiation_Course .flex-1 {
  flex: 1 1 0%;
}

.Formation_Dinitiation_Course .ml-4 {
  margin-left: 1rem;
}

.Formation_Dinitiation_Course .hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.Formation_Dinitiation_Course .hover\:text-sky-700:hover {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}
