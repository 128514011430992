.E_Trainings_Formateur .Dashboard.flex-1 {
  flex: 1 1 0%;
}

@media (min-width: 1024px) {
  .E_Trainings_Formateur .Dashboard .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .E_Trainings_Formateur .Dashboard .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.E_Trainings_Formateur .Dashboard .gap-6 {
  gap: 1.5rem;
}

.E_Trainings_Formateur .Dashboard .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.E_Trainings_Formateur .Dashboard .grid {
  display: grid;
}

.E_Trainings_Formateur .Dashboard .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.E_Trainings_Formateur .Dashboard .p-6 {
  padding: 1.5rem;
}

.E_Trainings_Formateur .Dashboard .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.E_Trainings_Formateur .Dashboard .rounded-xl {
  border-radius: 0.75rem;
}

.E_Trainings_Formateur .Dashboard .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254);
}

.E_Trainings_Formateur .Dashboard .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235);
}

.E_Trainings_Formateur .Dashboard .rounded-lg {
  border-radius: 0.5rem;
}

.E_Trainings_Formateur .Dashboard .ml-2 {
  margin-left: 0.5rem;
}

.E_Trainings_Formateur .Dashboard .text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94);
}

.E_Trainings_Formateur .Dashboard .text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68);
}

.E_Trainings_Formateur .Dashboard .hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216);
}

.E_Trainings_Formateur .Dashboard .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.E_Trainings_Formateur .Dashboard .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255);
}

.E_Trainings_Formateur .Dashboard .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235);
}

.E_Trainings_Formateur .Dashboard .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.E_Trainings_Formateur .Dashboard .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.E_Trainings_Formateur .Dashboard .border-l-4 {
  border-left-width: 4px;
}

.E_Trainings_Formateur .Dashboard .text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.E_Trainings_Formateur .Dashboard .text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.E_Trainings_Formateur .nftmax-adashboard.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.E_Trainings_Formateur .mt-8 {
  margin-top: 2rem !important;
}

.E_Trainings_Formateur .text-2xl,
.E_Trainings_Formateur .text-2xl\/snug {
  font-size: 1.5rem !important;
}

.E_Trainings_Formateur .text-2xl {
  line-height: 2rem !important;
}
