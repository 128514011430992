/* ------------------ start Commercial Online Register ---------------------------- */

@media (min-width: 1024px) {
  .CommercialOnlineRegister.lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 640px) {
  .CommercialOnlineRegister.sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.CommercialOnlineRegister.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.CommercialOnlineRegister.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.CommercialOnlineRegister.to-indigo-100 {
  --tw-gradient-to: #e0e7ff var(--tw-gradient-to-position);
}

.CommercialOnlineRegister.from-blue-50 {
  --tw-gradient-from: #eff6ff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.CommercialOnlineRegister.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.CommercialOnlineRegister.min-h-screen {
  min-height: 100vh;
}

.CommercialOnlineRegister .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.CommercialOnlineRegister .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.CommercialOnlineRegister .rounded-xl {
  border-radius: 0.75rem;
}

.CommercialOnlineRegister .max-w-3xl {
  max-width: 48rem;
}

.CommercialOnlineRegister .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.CommercialOnlineRegister .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.CommercialOnlineRegister .text-center {
  text-align: center;
}

.CommercialOnlineRegister .p-6 {
  padding: 1.5rem;
}

.CommercialOnlineRegister .bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.CommercialOnlineRegister .rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.CommercialOnlineRegister .w-12 {
  width: 3rem;
}

.CommercialOnlineRegister .h-12 {
  height: 3rem;
}

.CommercialOnlineRegister .mb-4 {
  margin-bottom: 1rem;
}

.CommercialOnlineRegister img,
.CommercialOnlineRegister svg,
.CommercialOnlineRegister video,
.CommercialOnlineRegister canvas,
.CommercialOnlineRegister audio,
.CommercialOnlineRegister iframe,
.CommercialOnlineRegister embed,
.CommercialOnlineRegister object {
  display: block;
  vertical-align: middle;
}

.CommercialOnlineRegister .font-bold {
  font-weight: 700;
}

.CommercialOnlineRegister .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.CommercialOnlineRegister blockquote,
.CommercialOnlineRegister dl,
.CommercialOnlineRegister dd,
.CommercialOnlineRegister h1,
.CommercialOnlineRegister h2,
.CommercialOnlineRegister h3,
.CommercialOnlineRegister h4,
.CommercialOnlineRegister h5,
.CommercialOnlineRegister h6,
.CommercialOnlineRegister hr,
.CommercialOnlineRegister figure,
.CommercialOnlineRegister p,
.CommercialOnlineRegister pre {
  margin: 0;
}

.CommercialOnlineRegister h1,
.CommercialOnlineRegister h2,
.CommercialOnlineRegister h3,
.CommercialOnlineRegister h4,
.CommercialOnlineRegister h5,
.CommercialOnlineRegister h6 {
  font-size: inherit;
  font-weight: inherit;
}

.CommercialOnlineRegister .p-8 {
  padding: 2rem;
}

.CommercialOnlineRegister .font-semibold {
  font-weight: 600;
}

.CommercialOnlineRegister .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.CommercialOnlineRegister .gap-2 {
  gap: 0.5rem;
}

.CommercialOnlineRegister .items-center {
  align-items: center;
}

.CommercialOnlineRegister .flex {
  display: flex;
}

.CommercialOnlineRegister .text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.CommercialOnlineRegister .w-5 {
  width: 1.25rem;
}

.CommercialOnlineRegister .h-5 {
  height: 1.25rem;
}

.CommercialOnlineRegister .space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.CommercialOnlineRegister .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.CommercialOnlineRegister .font-medium {
  font-weight: 500;
}

.CommercialOnlineRegister .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.CommercialOnlineRegister .block {
  display: block;
}

.CommercialOnlineRegister .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.CommercialOnlineRegister .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.CommercialOnlineRegister .rounded-md {
  border-radius: 0.375rem;
}

.CommercialOnlineRegister .w-full {
  width: 100%;
}

.CommercialOnlineRegister .mt-1 {
  margin-top: 0.25rem;
}

.CommercialOnlineRegister button,
.CommercialOnlineRegister input,
.CommercialOnlineRegister optgroup,
.CommercialOnlineRegister select,
.CommercialOnlineRegister textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.CommercialOnlineRegister .space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.CommercialOnlineRegister .space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.CommercialOnlineRegister .text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.CommercialOnlineRegister .w-4 {
  width: 1rem;
}

.CommercialOnlineRegister .h-4 {
  height: 1rem;
}

.CommercialOnlineRegister button,
.CommercialOnlineRegister input,
.CommercialOnlineRegister optgroup,
.CommercialOnlineRegister select,
.CommercialOnlineRegister textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.CommercialOnlineRegister .space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.CommercialOnlineRegister .pt-6 {
  padding-top: 1.5rem;
}

.CommercialOnlineRegister .border-t {
  border-top-width: 1px;
}

.CommercialOnlineRegister .items-start {
  align-items: flex-start;
}

.CommercialOnlineRegister .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.CommercialOnlineRegister .text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.CommercialOnlineRegister .list-disc {
  list-style-type: disc;
}

.CommercialOnlineRegister .list-inside {
  list-style-position: inside;
}

.CommercialOnlineRegister .mt-2 {
  margin-top: 0.5rem;
}

.CommercialOnlineRegister ol,
.CommercialOnlineRegister ul,
.CommercialOnlineRegister menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.CommercialOnlineRegister .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.CommercialOnlineRegister .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.CommercialOnlineRegister .bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.CommercialOnlineRegister .border-transparent {
  border-color: transparent;
}

.CommercialOnlineRegister .justify-center {
  justify-content: center;
}

.CommercialOnlineRegister button,
[role="button"] {
  cursor: pointer;
}

.CommercialOnlineRegister button,
.CommercialOnlineRegister input:where([type="button"]),
.CommercialOnlineRegister input:where([type="reset"]),
.CommercialOnlineRegister input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.CommercialOnlineRegister button,
.CommercialOnlineRegister select {
  text-transform: none;
}

.CommercialOnlineRegister button,
.CommercialOnlineRegister input,
.CommercialOnlineRegister optgroup,
.CommercialOnlineRegister select,
.CommercialOnlineRegister textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.CommercialOnlineRegister .text-center {
  text-align: center;
}

/* ------------------ end Commercial Online Register -------------------------------- */

/* ------------------ start Commercial Online Login --------------------------------- */
.CommercialOnlineLogin.to-blue-100 {
  --tw-gradient-to: #dbeafe var(--tw-gradient-to-position);
}

.CommercialOnlineLogin.from-blue-50 {
  --tw-gradient-from: #eff6ff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.CommercialOnlineLogin.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.CommercialOnlineLogin.min-h-screen {
  min-height: 100vh;
}

@media (min-width: 768px) {
  .CommercialOnlineLogin .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.CommercialOnlineLogin .py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.CommercialOnlineLogin .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.CommercialOnlineLogin .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/* ------------------------------------------------------------- */

@media (min-width: 768px) {
  .CommercialOnlineLogin .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.CommercialOnlineLogin .gap-12 {
  gap: 3rem;
}

.CommercialOnlineLogin .items-center {
  align-items: center;
}

.CommercialOnlineLogin .grid {
  display: grid;
}

@media (min-width: 768px) {
  .CommercialOnlineLogin .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

.CommercialOnlineLogin .text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.CommercialOnlineLogin .font-bold {
  font-weight: 700;
}

.CommercialOnlineLogin .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.CommercialOnlineLogin blockquote,
.CommercialOnlineLogin dl,
.CommercialOnlineLogin dd,
.CommercialOnlineLogin h1,
.CommercialOnlineLogin h2,
.CommercialOnlineLogin h3,
.CommercialOnlineLogin h4,
.CommercialOnlineLogin h5,
.CommercialOnlineLogin h6,
.CommercialOnlineLogin hr,
.CommercialOnlineLogin figure,
.CommercialOnlineLogin p,
.CommercialOnlineLogin pre {
  margin: 0;
}

.CommercialOnlineLogin h1,
.CommercialOnlineLogin h2,
.CommercialOnlineLogin h3,
.CommercialOnlineLogin h4,
.CommercialOnlineLogin h5,
.CommercialOnlineLogin h6 {
  font-size: inherit;
  font-weight: inherit;
}

.CommercialOnlineLogin .space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

@media (min-width: 768px) {
  .CommercialOnlineLogin .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.CommercialOnlineLogin .text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.CommercialOnlineLogin .font-semibold {
  font-weight: 600;
}

.CommercialOnlineLogin .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.CommercialOnlineLogin .text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.CommercialOnlineLogin .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.CommercialOnlineLogin .space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.CommercialOnlineLogin .relative {
  position: relative;
}

.CommercialOnlineLogin .text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.CommercialOnlineLogin .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.CommercialOnlineLogin .-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.CommercialOnlineLogin .top-1\/2 {
  top: 50%;
}

.CommercialOnlineLogin .left-3 {
  left: 0.75rem;
}

.CommercialOnlineLogin .absolute {
  position: absolute;
}

.CommercialOnlineLogin img,
.CommercialOnlineLogin svg,
.CommercialOnlineLogin video,
.CommercialOnlineLogin canvas,
.CommercialOnlineLogin audio,
.CommercialOnlineLogin iframe,
.CommercialOnlineLogin embed,
.CommercialOnlineLogin object {
  display: block;
  vertical-align: middle;
}

.CommercialOnlineLogin .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.CommercialOnlineLogin .outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.CommercialOnlineLogin .pr-4 {
  padding-right: 1rem;
}

.CommercialOnlineLogin .pl-10 {
  padding-left: 2.5rem;
}

.CommercialOnlineLogin .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.CommercialOnlineLogin .border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

.CommercialOnlineLogin .border {
  border-width: 1px;
}

.CommercialOnlineLogin .rounded-lg {
  border-radius: 0.5rem;
}

.CommercialOnlineLogin .w-full {
  width: 100%;
}

.CommercialOnlineLogin button,
.CommercialOnlineLogin input,
.CommercialOnlineLogin optgroup,
.CommercialOnlineLogin select,
.CommercialOnlineLogin textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.CommercialOnlineLogin .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.CommercialOnlineLogin .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.CommercialOnlineLogin .to-blue-600 {
  background: linear-gradient(45deg, #3b82f6, #2563eb);
}

.CommercialOnlineLogin .hover\:to-blue-700:hover {
  background: linear-gradient(45deg, #2563eb, #1d4ed8);
}

.CommercialOnlineLogin .shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.CommercialOnlineLogin .p-8 {
  padding: 2rem;
}

.CommercialOnlineLogin .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.CommercialOnlineLogin .rounded-2xl {
  border-radius: 1rem;
}

.CommercialOnlineLogin .mb-6 {
  margin-bottom: 1.5rem;
}

.CommercialOnlineLogin .items-start {
  align-items: flex-start;
}

.CommercialOnlineLogin .flex {
  display: flex;
}

.CommercialOnlineLogin .p-3 {
  padding: 0.75rem;
}

.CommercialOnlineLogin .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.CommercialOnlineLogin .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.CommercialOnlineLogin .space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

/* ------------------ end Commercial Online Login ----------------------------------- */
