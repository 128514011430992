.Formation_Dinitiation .to-blue-100 {
  --tw-gradient-to: #dbeafe var(--tw-gradient-to-position);
}

.Formation_Dinitiation .from-blue-50 {
  --tw-gradient-from: #eff6ff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.Formation_Dinitiation .bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.Formation_Dinitiation .min-h-screen {
  min-height: 100vh;
}

@media (min-width: 768px) {
  .Formation_Dinitiation .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.Formation_Dinitiation .py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.Formation_Dinitiation .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Formation_Dinitiation .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .Formation_Dinitiation .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.Formation_Dinitiation .gap-12 {
  gap: 3rem;
}

.Formation_Dinitiation .items-center {
  align-items: center;
}

.Formation_Dinitiation .grid {
  display: grid;
}

.Formation_Dinitiation .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Formation_Dinitiation .p-8 {
  padding: 2rem;
}

.Formation_Dinitiation .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.Formation_Dinitiation .rounded-2xl {
  border-radius: 1rem;
}

.Formation_Dinitiation .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.Formation_Dinitiation .font-bold {
  font-weight: 700;
}

.Formation_Dinitiation .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.Formation_Dinitiation .mb-6 {
  margin-bottom: 1.5rem;
}

.Formation_Dinitiation .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.Formation_Dinitiation .mb-8 {
  margin-bottom: 2rem;
}

.Formation_Dinitiation .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.Formation_Dinitiation .font-medium {
  font-weight: 500;
}

.Formation_Dinitiation .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Formation_Dinitiation .block {
  display: block;
}

.Formation_Dinitiation .mb-2 {
  margin-bottom: 0.5rem;
}

.Formation_Dinitiation .relative {
  position: relative;
}

.Formation_Dinitiation .space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.Formation_Dinitiation .duration-200 {
  transition-duration: 200ms;
}

.Formation_Dinitiation .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Formation_Dinitiation .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Formation_Dinitiation .font-medium {
  font-weight: 500;
}

.Formation_Dinitiation .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.Formation_Dinitiation .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.Formation_Dinitiation .to-indigo-600 {
  background: linear-gradient(45deg, #3b82f6, #4f46e5);
}

.Formation_Dinitiation .to-blue-500 {
  background: linear-gradient(45deg, #3b82f6, #4f46e5);
}

.Formation_Dinitiation .rounded-lg {
  border-radius: 0.5rem;
}

.Formation_Dinitiation .w-full {
  width: 100%;
}

.Formation_Dinitiation .hover\:to-indigo-700:hover {
  background: linear-gradient(45deg, #2563eb, #4338ca);
}

.Formation_Dinitiation .hover\:to-blue-600:hover {
  background: linear-gradient(45deg, #2563eb, #4338ca);
}

.Formation_Dinitiation .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.Formation_Dinitiation .gap-6 {
  gap: 1.5rem;
}

.Formation_Dinitiation .p-4 {
  padding: 1rem;
}

.Formation_Dinitiation .bg-white\/10 {
  background-color: rgb(255 255 255 / 0.1);
}

.Formation_Dinitiation .rounded-lg {
  border-radius: 0.5rem;
}

.Formation_Dinitiation .items-start {
  align-items: flex-start;
}

.Formation_Dinitiation .flex {
  display: flex;
}

.Formation_Dinitiation .space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.Formation_Dinitiation .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.Formation_Dinitiation .top-3 {
  top: 1.2rem;
}

.Formation_Dinitiation .left-3 {
  left: 0.75rem;
}

.Formation_Dinitiation .absolute {
  position: absolute;
}

.Formation_Dinitiation .pr-4 {
  padding-right: 1rem;
}

.Formation_Dinitiation .pl-10 {
  padding-left: 2.5rem;
}

.Formation_Dinitiation .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.Formation_Dinitiation .to-blue-800 {
  background: linear-gradient(90deg, #2563eb, #1e40af);
}

.Formation_Dinitiation .tracking-tight {
  letter-spacing: -0.025em;
}

.Formation_Dinitiation .font-bold {
  font-weight: 700;
}

.Formation_Dinitiation .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.Formation_Dinitiation .text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.Formation_Dinitiation .leading-relaxed {
  line-height: 1.625;
}

.Formation_Dinitiation .font-medium {
  font-weight: 500;
}

.Formation_Dinitiation .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
