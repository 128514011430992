.Seller_Dashboard .p-6 {
  padding: 1.5rem;
}

@media (min-width: 768px) {
  .Seller_Dashboard .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.Seller_Dashboard .gap-6 {
  gap: 1.5rem;
}

.Seller_Dashboard .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.Seller_Dashboard .grid {
  display: grid;
}

.Seller_Dashboard .mb-8 {
  margin-bottom: 2rem;
}

.Seller_Dashboard .rounded-lg {
  border-radius: 0.5rem;
}

.Seller_Dashboard .p-4 {
  padding: 1rem;
}

.Seller_Dashboard .bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.Seller_Dashboard .space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.Seller_Dashboard .text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.Seller_Dashboard .font-semibold {
  font-weight: 600;
}

.Seller_Dashboard .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.Seller_Dashboard .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.Seller_Dashboard .bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.Seller_Dashboard .text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.Seller_Dashboard .text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.Seller_Dashboard .text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}

.Seller_Dashboard .bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.Seller_Dashboard .text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.Seller_Dashboard .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Seller_Dashboard .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Seller_Dashboard .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Seller_Dashboard .hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.Seller_Dashboard .bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.Seller_Dashboard .cursor-not-allowed {
  cursor: not-allowed;
}

.Seller_Dashboard .bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
  .Seller_Dashboard .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .Seller_Dashboard .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.Seller_Dashboard .gap-6 {
  gap: 1.5rem;
}

.Seller_Dashboard .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.Seller_Dashboard .grid {
  display: grid;
}

.Seller_Dashboard .text-2xl {
  line-height: 2rem !important;
}

.Seller_Dashboard .font-bold {
  font-weight: 700 !important;
}

.Seller_Dashboard .text-2xl,
.Seller_Dashboard .text-2xl\/snug {
  font-size: 1.5rem !important;
}

.Seller_Dashboard h5 {
  font-size: 22px !important;
  font-weight: 700 !important;
}
