.reactitment .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.reactitment .to-blue-800 {
  background: linear-gradient(to bottom right, #2563eb, #1e40af);
}

.reactitment .relative {
  position: relative;
}

@media (min-width: 1024px) {
  .reactitment .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 640px) {
  .reactitment .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.reactitment .py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.reactitment .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.reactitment .max-w-7xl {
  max-width: 80rem;
}

.reactitment .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.reactitment .text-center {
  text-align: center;
}

.reactitment .font-bold {
  font-weight: 700 !important;
}

.reactitment .mb-6 {
  margin-bottom: 1.5rem;
}

.reactitment blockquote {
  margin: 0;
}

@media (min-width: 768px) {
  .reactitment .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.reactitment .text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.reactitment .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.reactitment .mb-8 {
  margin-bottom: 2rem;
}

.reactitment .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.reactitment .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.reactitment .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.reactitment .font-semibold {
  font-weight: 600;
}

.reactitment .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.reactitment .py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.reactitment .px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.reactitment .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.reactitment .rounded-lg {
  border-radius: 0.5rem;
}

.reactitment button,
[role="button"] {
  cursor: pointer;
}

.reactitment button,
.reactitment input:where([type="button"]),
.reactitment input:where([type="reset"]),
.reactitment input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

@media (min-width: 768px) {
  .reactitment .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

.reactitment .gap-8 {
  gap: 2rem;
}

.reactitment .grid {
  display: grid;
}

.reactitment .mt-20 {
  margin-top: 5rem;
}

.reactitment .backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}

.reactitment .p-6 {
  padding: 1.5rem;
}

.reactitment .bg-white\/10 {
  background-color: rgb(255 255 255 / 0.1);
}

.reactitment .rounded-xl {
  border-radius: 0.75rem;
}

.reactitment .w-12 {
  width: 3rem;
}

.reactitment .h-12 {
  height: 3rem;
}

.reactitment .mb-4 {
  margin-bottom: 1rem;
}

.reactitment img,
.reactitment svg,
.reactitment video,
.reactitment canvas,
.reactitment audio,
.reactitment iframe,
.reactitment embed,
.reactitment object {
  display: block;
  vertical-align: middle;
}

.reactitment .py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.reactitment .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
  .reactitment .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 640px) {
  .reactitment .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.reactitment .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.reactitment .max-w-7xl {
  max-width: 80rem;
}

.reactitment .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.reactitment .mb-12 {
  margin-bottom: 3rem;
}

.reactitment .font-extrabold {
  font-weight: 800 !important;
}

.reactitment .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.reactitment .mb-3 {
  margin-bottom: 0.75rem;
}

.reactitment .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  .reactitment .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

@media (min-width: 768px) {
  .reactitment .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.reactitment .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.reactitment .shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.reactitment .overflow-hidden {
  overflow: hidden;
}

.reactitment .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.reactitment .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.reactitment .hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.reactitment .hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.reactitment .hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

/* ----------------- reactitment form -------------------------- */

@media (min-width: 1024px) {
  .reactitment-form .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 640px) {
  .reactitment-form .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.reactitment-form .py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.reactitment-form .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.reactitment-form .max-w-7xl {
  max-width: 80rem;
}

.reactitment-form .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.Results.bg-gray-50,
.reactitment-form.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.reactitment-form .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.reactitment-form .p-8 {
  padding: 2rem;
}

.reactitment-form .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.reactitment-form .rounded-2xl {
  border-radius: 1rem;
}

.reactitment-form .mb-8 {
  margin-bottom: 2rem;
}

.reactitment-form .bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.reactitment-form .rounded-full {
  border-radius: 9999px;
}

.reactitment-form .h-2 {
  height: 0.5rem;
}

.reactitment-form .duration-300 {
  transition-duration: 300ms;
}

.reactitment-form .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.reactitment-form .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.reactitment-form .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.reactitment-form .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.reactitment-form .mt-2 {
  margin-top: 0.5rem;
}

.reactitment-form .p-6 {
  padding: 1.5rem;
}

.reactitment-form .max-w-2xl {
  max-width: 42rem;
}

.reactitment-form .w-full {
  width: 100%;
}

.reactitment-form .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.reactitment-form .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.reactitment-form .font-semibold {
  font-weight: 600;
}

.reactitment-form .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.reactitment-form .mb-6 {
  margin-bottom: 1.5rem;
}

.reactitment-form .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.reactitment-form .p-4 {
  padding: 1rem;
}

.reactitment-form .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.reactitment-form .border {
  border-width: 1px !important;
}

.reactitment-form .rounded-lg {
  border-radius: 0.5rem;
}

.reactitment-form .cursor-pointer {
  cursor: pointer;
}

.reactitment-form .block {
  display: block;
}

.reactitment-form .hover\:border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}

.reactitment-form .mr-3 {
  margin-right: 0.75rem;
}

.reactitment-form .border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.reactitment-form .bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.reactitment-form .space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.reactitment-form .justify-between {
  justify-content: space-between;
}

.reactitment-form .flex {
  display: flex;
}

.reactitment-form .mt-8 {
  margin-top: 2rem;
}

.reactitment-form .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.reactitment-form .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.reactitment-form .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.reactitment-form .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.reactitment-form .rounded-lg {
  border-radius: 0.5rem;
}

.reactitment-form .items-center {
  align-items: center;
}

.reactitment-form .cursor-not-allowed {
  cursor: not-allowed;
}

.reactitment-form .text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.reactitment-form .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

/* --------------------- Results ------------------------------------------ */
@media (min-width: 1024px) {
  .Results .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 640px) {
  .Results .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.Results .py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.Results .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Results .max-w-7xl {
  max-width: 80rem;
}

.Results .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.Results .text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.Results .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Results .py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.Results .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.Results .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.Results .rounded-full {
  border-radius: 9999px;
}

.Results .mb-8 {
  margin-bottom: 2rem;
}

@media (min-width: 1024px) {
  .Results .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

@media (min-width: 768px) {
  .Results .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.Results .gap-6 {
  gap: 1.5rem;
}

.Results .grid {
  display: grid;
}

.Results .transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Results .shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.Results .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.Results .rounded-xl {
  border-radius: 0.75rem;
}

.Results .overflow-hidden {
  overflow: hidden;
}

.Results .hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.Results .mb-4 {
  margin-bottom: 1rem;
}

.Results .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.Results .rounded-full {
  border-radius: 9999px;
}

.Results .w-16 {
  width: 4rem;
}

.Results .h-16 {
  height: 4rem;
}

.Results .ml-4 {
  margin-left: 1rem;
}

.Results .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.Results .font-semibold {
  font-weight: 600;
}

.Results .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.Results .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.Results .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Results .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Results .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Results .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.Results .rounded-lg {
  border-radius: 0.5rem;
}

.Results .w-full {
  width: 100%;
}

.Results .hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}
