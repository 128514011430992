.Dashboard_Login.p-4 {
  padding: 1rem;
}

.Dashboard_Login.to-blue-200 {
  background: linear-gradient(to bottom right, #dbeafe, #bfdbfe);
}

.Dashboard_Login.justify-center {
  justify-content: center;
}

.Dashboard_Login.items-center {
  align-items: center;
}

.Dashboard_Login.min-h-screen {
  min-height: 100vh;
}

.Dashboard_Login.flex {
  display: flex;
}

.Dashboard_Login .max-w-md {
  max-width: 28rem;
}

.Dashboard_Login .w-full {
  width: 100%;
}

.Dashboard_Login .shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Dashboard_Login .p-8 {
  padding: 2rem;
}

.Dashboard_Login .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.Dashboard_Login .rounded-2xl {
  border-radius: 1rem;
}

.Dashboard_Login .text-center {
  text-align: center;
}

.Dashboard_Login .justify-center {
  justify-content: center;
}

.Dashboard_Login .flex {
  display: flex;
}

.Dashboard_Login .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.Dashboard_Login .w-12 {
  width: 3rem;
}

.Dashboard_Login .h-12 {
  height: 3rem;
}

.Dashboard_Login img,
.Dashboard_Login svg,
.Dashboard_Login video,
.Dashboard_Login canvas,
.Dashboard_Login audio,
.Dashboard_Login iframe,
.Dashboard_Login embed,
.Dashboard_Login object {
  display: block;
  vertical-align: middle;
}

.Dashboard_Login .space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.Dashboard_Login .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.Dashboard_Login .font-bold {
  font-weight: 700;
}

.Dashboard_Login .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.Dashboard_Login blockquote,
.Dashboard_Login dl,
.Dashboard_Login dd,
.Dashboard_Login h1,
.Dashboard_Login h2,
.Dashboard_Login h3,
.Dashboard_Login h4,
.Dashboard_Login h5,
.Dashboard_Login h6,
.Dashboard_Login hr,
.Dashboard_Login figure,
.Dashboard_Login p,
.Dashboard_Login pre {
  margin: 0;
}

.Dashboard_Login h1,
.Dashboard_Login h2,
.Dashboard_Login h3,
.Dashboard_Login h4,
.Dashboard_Login h5,
.Dashboard_Login h6 {
  font-size: inherit;
  font-weight: inherit;
}

.Dashboard_Login .space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.Dashboard_Login .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.Dashboard_Login .font-medium {
  font-weight: 500;
}

.Dashboard_Login .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Dashboard_Login .relative {
  position: relative;
}

.Dashboard_Login .pl-3 {
  padding-left: 0.75rem;
}

.Dashboard_Login .items-center {
  align-items: center;
}

.Dashboard_Login .flex {
  display: flex;
}

.Dashboard_Login .left-0 {
  left: 0px;
}

.Dashboard_Login .inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.Dashboard_Login .absolute {
  position: absolute;
}

.Dashboard_Login .pointer-events-none {
  pointer-events: none;
}

.Dashboard_Login .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.Dashboard_Login .pr-3 {
  padding-right: 0.75rem;
}

.Dashboard_Login .pl-10 {
  padding-left: 2.5rem;
}

.Dashboard_Login .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.Dashboard_Login .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.Dashboard_Login .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.Dashboard_Login .border {
  border-width: 1px;
}

.Dashboard_Login .rounded-lg {
  border-radius: 0.5rem;
}

.Dashboard_Login .w-full {
  width: 100%;
}

.Dashboard_Login .block {
  display: block;
}

.Dashboard_Login button,
.Dashboard_Login input,
.Dashboard_Login optgroup,
.Dashboard_Login select,
.Dashboard_Login textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.Dashboard_Login .space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.Dashboard_Login .ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.Dashboard_Login .duration-200 {
  transition-duration: 200ms;
}

.Dashboard_Login .transition {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Dashboard_Login .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Dashboard_Login .font-medium {
  font-weight: 500;
}

.Dashboard_Login .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.Dashboard_Login .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Dashboard_Login .to-blue-600 {
  background: linear-gradient(to right, #3b82f6, #2563eb);
}

.Dashboard_Login .rounded-lg {
  border-radius: 0.5rem;
}

.Dashboard_Login .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.Dashboard_Login .w-full {
  width: 100%;
}

.Dashboard_Login button,
[role="button"] {
  cursor: pointer;
}

.Dashboard_Login button,
.Dashboard_Login input:where([type="button"]),
.Dashboard_Login input:where([type="reset"]),
.Dashboard_Login input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.Dashboard_Login button,
.Dashboard_Login select {
  text-transform: none;
}

.Dashboard_Login button,
.Dashboard_Login input,
.Dashboard_Login optgroup,
.Dashboard_Login select,
.Dashboard_Login textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.Dashboard_Login .hover\:to-blue-700:hover {
  background: linear-gradient(to right, #2563eb, #1d4ed8);
}
