:root {
  --tw-translate-x: 1rem;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.E_Trainings_available blockquote,
.E_Trainings_available dl,
.E_Trainings_available dd,
.E_Trainings_available h1,
.E_Trainings_available h2,
.E_Trainings_available h3,
.E_Trainings_available h4,
.E_Trainings_available h5,
.E_Trainings_available h6,
.E_Trainings_available hr,
.E_Trainings_available figure,
.E_Trainings_available p,
.E_Trainings_available pre {
  margin: 0;
}

.E_Trainings_available h1,
.E_Trainings_available h2,
.E_Trainings_available h3,
.E_Trainings_available h4,
.E_Trainings_available h5,
.E_Trainings_available h6 {
  font-size: inherit;
  font-weight: inherit;
}

.E_Trainings_available .py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.E_Trainings_available .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.E_Trainings_available .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.E_Trainings_available .max-w-6xl {
  max-width: 72rem;
}

.E_Trainings_available .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.E_Trainings_available .mb-12 {
  margin-bottom: 3rem;
}

@media (min-width: 724px) {
  .E_Trainings_available .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.E_Trainings_available .gap-12 {
  gap: 3rem;
}

.E_Trainings_available .items-center {
  align-items: center;
}

.E_Trainings_available .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.E_Trainings_available .grid {
  display: grid;
}

.E_Trainings_available .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.E_Trainings_available .leading-relaxed {
  line-height: 1.625;
}

.E_Trainings_available .relative {
  position: relative;
}

.E_Trainings_available .bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}

.E_Trainings_available .rounded-full {
  border-radius: 9999px;
}

.E_Trainings_available .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.E_Trainings_available .translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.E_Trainings_available .translate-x-4 {
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.E_Trainings_available .-z-10 {
  z-index: -10;
}

.E_Trainings_available .inset-0 {
  inset: 0px;
}

.E_Trainings_available .absolute {
  position: absolute;
}

.rounded-2xl {
  border-radius: 1rem;
}

.E_Trainings_available .overflow-hidden {
  overflow: hidden;
}

.E_Trainings_available .aspect-\[4\/3\] {
  aspect-ratio: 4 / 3;
}

.E_Trainings_available .relative {
  position: relative;
}

.E_Trainings_available .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.E_Trainings_available .w-full {
  width: 100%;
}

.E_Trainings_available .h-full {
  height: 100%;
}

.E_Trainings_available img,
video {
  max-width: 100%;
  height: auto;
}

.E_Trainings_available img,
.E_Trainings_available svg,
.E_Trainings_available video,
.E_Trainings_available canvas,
.E_Trainings_available audio,
.E_Trainings_available iframe,
.E_Trainings_available embed,
.E_Trainings_available object {
  display: block;
  vertical-align: middle;
}

.E_Trainings_available .items-center {
  align-items: center;
}

.E_Trainings_available .flex {
  display: flex;
}

.E_Trainings_available .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.E_Trainings_available .right-4 {
  right: 1rem;
}

.E_Trainings_available .left-4 {
  left: 1rem;
}

.E_Trainings_available .bottom-4 {
  bottom: 1rem;
}

.E_Trainings_available .absolute {
  position: absolute;
}

.E_Trainings_available .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.E_Trainings_available .rounded-full {
  border-radius: 9999px;
}

.E_Trainings_available .justify-center {
  justify-content: center;
}

.E_Trainings_available .items-center {
  align-items: center;
}

.E_Trainings_available .w-12 {
  width: 3rem;
}

.E_Trainings_available .h-12 {
  height: 3rem;
}

.E_Trainings_available .w-6 {
  width: 1.5rem;
}

.E_Trainings_available .h-6 {
  height: 1.5rem;
}

.E_Trainings_available .space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.E_Trainings_available .font-medium {
  font-weight: 500;
}
/* -------------------------------------------------------------- */
.E_Trainings_available.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.E_Trainings_available.to-indigo-700 {
  background: linear-gradient(90deg, #2563eb, #4338ca);
}

.E_Trainings_available .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.E_Trainings_available .max-w-6xl {
  max-width: 72rem;
}

.E_Trainings_available .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.E_Trainings_available .gap-8 {
  gap: 2rem;
}

.E_Trainings_available .grid {
  display: grid;
}

.E_Trainings_available .duration-300 {
  transition-duration: 300ms;
}

.E_Trainings_available .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.E_Trainings_available .backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}

.E_Trainings_available .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.E_Trainings_available .p-6 {
  padding: 1.5rem;
}

.E_Trainings_available .bg-white\/10 {
  background-color: rgb(255 255 255 / 0.1);
}

.E_Trainings_available .rounded-2xl {
  border-radius: 1rem;
}

.E_Trainings_available .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.E_Trainings_available .p-3 {
  padding: 0.75rem;
}

.E_Trainings_available .bg-white\/20 {
  background-color: rgb(255 255 255 / 0.2);
}

.E_Trainings_available .rounded-xl {
  border-radius: 0.75rem;
}

.E_Trainings_available .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.E_Trainings_available .text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.E_Trainings_available .text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.E_Trainings_available .text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.E_Trainings_available .space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.E_Trainings_available .font-bold {
  font-weight: 700;
}

.E_Trainings_available .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.E_Trainings_available .mb-1 {
  margin-bottom: 0.25rem;
}

.E_Trainings_available .text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.E_Trainings_available .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
