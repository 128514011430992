.Formation_Participant .to-blue-100 {
  --tw-gradient-to: #dbeafe var(--tw-gradient-to-position);
}

.Formation_Participant .from-blue-50 {
  --tw-gradient-from: #eff6ff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.Formation_Participant .bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.Formation_Participant .min-h-screen {
  min-height: 100vh;
}

@media (min-width: 768px) {
  .Formation_Participant .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.Formation_Participant .py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.Formation_Participant .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Formation_Participant .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .Formation_Participant .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.Formation_Participant .gap-12 {
  gap: 3rem;
}

.Formation_Participant .items-center {
  align-items: center;
}

.Formation_Participant .grid {
  display: grid;
}

.Formation_Participant .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Formation_Participant .p-8 {
  padding: 2rem;
}

.Formation_Participant .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.Formation_Participant .rounded-2xl {
  border-radius: 1rem;
}

.Formation_Participant .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.Formation_Participant .font-bold {
  font-weight: 700;
}

.Formation_Participant .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.Formation_Participant .mb-6 {
  margin-bottom: 1.5rem;
}

.Formation_Participant .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.Formation_Participant .mb-8 {
  margin-bottom: 2rem;
}

.Formation_Participant .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.Formation_Participant .font-medium {
  font-weight: 500;
}

.Formation_Participant .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Formation_Participant .block {
  display: block;
}

.Formation_Participant .mb-2 {
  margin-bottom: 0.5rem;
}

.Formation_Participant .relative {
  position: relative;
}

.Formation_Participant .space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.Formation_Participant .duration-200 {
  transition-duration: 200ms;
}

.Formation_Participant .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Formation_Participant .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Formation_Participant .font-medium {
  font-weight: 500;
}

.Formation_Participant .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.Formation_Participant .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.Formation_Participant .to-indigo-600 {
  background: linear-gradient(45deg, #3b82f6, #4f46e5);
}

.Formation_Participant .rounded-lg {
  border-radius: 0.5rem;
}

.Formation_Participant .w-full {
  width: 100%;
}

.Formation_Participant .hover\:to-indigo-700:hover {
  background: linear-gradient(45deg, #2563eb, #4338ca);
}

.Formation_Participant .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.Formation_Participant .gap-6 {
  gap: 1.5rem;
}

.Formation_Participant .p-4 {
  padding: 1rem;
}

.Formation_Participant .bg-white\/10 {
  background-color: rgb(255 255 255 / 0.1);
}

.Formation_Participant .rounded-lg {
  border-radius: 0.5rem;
}

.Formation_Participant .items-start {
  align-items: flex-start;
}

.Formation_Participant .flex {
  display: flex;
}

.Formation_Participant .space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
