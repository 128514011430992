/* .Verivication-Detail {
  background: var(--background-color);
  color: var(--text-color);
  padding: 2rem;
  min-height: 100vh;
}

.Verivication-Detail .dashboard {
  max-width: 1400px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.Verivication-Detail .header {
  text-align: center;
  margin-bottom: 2rem;
  padding: 2rem;
  background: linear-gradient(135deg, #4361ee, #3f37c9);
  border-radius: 15px;
  color: white !important;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.Verivication-Detail header h1 {
  font-size: 2rem;
}

.Verivication-Detail header h1,
.Verivication-Detail header p {
  color: #fff;
}

.Verivication-Detail header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  animation: shine 2s infinite;
}

@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.Tableau_De_Bord_Des_Performances .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.Tableau_De_Bord_Des_Performances .section {
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.9);
}

.Tableau_De_Bord_Des_Performances .section:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.Tableau_De_Bord_Des_Performances .section-title {
  color: #4361ee;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #4361ee;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Tableau_De_Bord_Des_Performances .section-title i {
  font-size: 1.2rem;
}

.Tableau_De_Bord_Des_Performances .metric {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.Tableau_De_Bord_Des_Performances .metric:hover {
  background: rgba(255, 255, 255, 0.8);
}

.Tableau_De_Bord_Des_Performances .metric-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  align-items: center;
}

.Tableau_De_Bord_Des_Performances .metric-name {
  font-weight: 500;
  color: #2b2d42;
  flex: 1;
}

.Tableau_De_Bord_Des_Performances .metric-value {
  font-weight: bold;
  color: #4361ee;
  font-size: 1.2rem;
  min-width: 60px;
  text-align: right;
}

.Tableau_De_Bord_Des_Performances .progress-bar {
  width: 100%;
  height: 12px;
  background: #e9ecef;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.Tableau_De_Bord_Des_Performances .progress {
  height: 100%;
  background: linear-gradient(90deg, #4361ee, #3f37c9);
  border-radius: 6px;
  transition: width 1.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.Tableau_De_Bord_Des_Performances .progress::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: shimmer 2s infinite;
}

.Tableau_De_Bord_Des_Performances .charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.Tableau_De_Bord_Des_Performances .chart-wrapper {
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.Tableau_De_Bord_Des_Performances .status-indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

/* .status-low {
  background-color: #e74c3c;
} */
/* .status-medium {
  background-color: var(--warning-color);
} */
/* .status-high {
  background-color: #2ecc71;
} *

.Tableau_De_Bord_Des_Performances .tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white !important;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.Tableau_De_Bord_Des_Performances .metric:hover .tooltip {
  opacity: 1;
}

.Tableau_De_Bord_Des_Performances .summary-card {
  background: linear-gradient(135deg, #4361ee, #3f37c9);
  color: white !important;
  padding: 1.5rem;
  border-radius: 15px;
  margin-bottom: 2rem;
  text-align: center;
}

.Tableau_De_Bord_Des_Performances .summary-card h2 {
  font-size: 2rem;
}

.Tableau_De_Bord_Des_Performances .summary-card h2,
.Tableau_De_Bord_Des_Performances .summary-card p {
  color: #fff;
}

@media (max-width: 768px) {
  .Tableau_De_Bord_Des_Performances {
    padding: 1rem;
  }

  .Tableau_De_Bord_Des_Performances .grid-container {
    grid-template-columns: 1fr;
  }

  .Tableau_De_Bord_Des_Performances .section {
    padding: 1.5rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Tableau_De_Bord_Des_Performances .fade-in {
  animation: fadeIn 0.5s ease forwards;
}

.Tableau_De_Bord_Des_Performances .loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  background: #4361ee;
  transition: width 0.3s;
} */
/* --------------------------------------------------------------------------------- */
/* ---------------------------------- start nav ----------------------------------------- */

.Verivication-Detail .profile-container {
  max-width: 800px;
  width: 100%;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.Verivication-Detail .navSide {
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  padding: 3rem 2rem;
  text-align: center;
  position: relative;
}

.Verivication-Detail .navSide::after {
  content: "";
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  height: 100px;
  background: #ffffff;
  clip-path: ellipse(70% 50% at 50% 50%);
}

.Verivication-Detail .profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  position: relative;
  z-index: 2;
  transition: transform 0.3s ease;
  margin: auto;
}

.Verivication-Detail .profile-image:hover {
  transform: scale(1.05);
}

.Verivication-Detail .name {
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 2;
}

.Verivication-Detail .title {
  color: #dbeafe;
  font-size: 1.2rem;
  position: relative;
  z-index: 2;
}

.Verivication-Detail .menu-container {
  padding: 10px;
}

.Verivication-Detail .menu-item {
  background: #ffffff;
  border-radius: 16px;
  padding: 1.2rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.Verivication-Detail .menu-item.active,
.Verivication-Detail .menu-item:hover {
  transform: translateY(-3px);
  border-color: #60a5fa;
  background: #dbeafe;
}

.Verivication-Detail .menu-icon {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2563eb;
  border-radius: 12px;
  color: white;
}

.Verivication-Detail .menu-text {
  color: #1e293b;
  font-weight: 500;
  font-size: 1.1rem;
}

.Verivication-Detail .download-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  color: white;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 500;
  margin: auto;
  margin-top: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(37, 99, 235, 0.2);
}

.Verivication-Detail .download-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(37, 99, 235, 0.3);
}

.Verivication-Detail .download-icon {
  margin-left: 0.5rem;
}

@media (max-width: 480px) {
  .Verivication-Detail .profile-container {
    border-radius: 16px;
  }

  .Verivication-Detail .navSide {
    padding: 2rem 1rem;
  }

  .Verivication-Detail .profile-image {
    width: 120px;
    height: 120px;
  }

  .Verivication-Detail .name {
    font-size: 1.5rem;
  }

  .Verivication-Detail .menu-container {
    padding: 1rem;
  }
}

/* ---------------------------------- end nav ------------------------------------------- */

/* ---------------------------------- start Évaluation globale -------------------------------- */
.Verivication-Detail .Évaluation .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.Verivication-Detail .Évaluation .text-center {
  text-align: center;
}

.Verivication-Detail .Évaluation .p-8 {
  padding: 2rem;
}

.Verivication-Detail .Évaluation .bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity, 1));
}

.Verivication-Detail .Évaluation .rounded-xl {
  border-radius: 0.75rem;
}

.Verivication-Detail .Évaluation .mb-8 {
  margin-bottom: 2rem;
}

.Verivication-Detail .Évaluation .font-bold {
  font-weight: 700 !important;
}

.Verivication-Detail .Évaluation .text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.Verivication-Detail .Évaluation .mb-2 {
  margin-bottom: 0.5rem;
}

.Verivication-Detail .Évaluation .text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.Verivication-Detail .Évaluation .mb-4 {
  margin-bottom: 1rem;
}

.Verivication-Detail .Évaluation .opacity-90 {
  opacity: 0.9;
}

.Verivication-Detail .Évaluation .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Verivication-Detail .Évaluation .p-4 {
  padding: 1rem;
}

.Verivication-Detail .Évaluation .bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity, 1));
}

.Verivication-Detail .Évaluation .bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}

.Verivication-Detail .Évaluation .bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}

.Verivication-Detail .Évaluation .bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity, 1));
}

/* ---------------------------------- end Évaluation globale ---------------------------------- */

/* ---------------------------------- start Recommandation -------------------------------- */

.Verivication-Detail .Recommandation .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.Verivication-Detail .Recommandation .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.Verivication-Detail .Recommandation .bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

.Verivication-Detail .Recommandation .text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.Verivication-Detail .Recommandation .font-semibold {
  font-weight: 600;
}

.Verivication-Detail .Recommandation .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.E_Trainings_Certificate_Detai
  .gallery
  .space-y-6
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.Verivication-Detail .Recommandation .pt-4 {
  padding-top: 1rem;
}

.Verivication-Detail .Recommandation .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.Verivication-Detail .Recommandation .border-t {
  border-top-width: 1px;
}

.Verivication-Detail .Recommandation .mt-8 {
  margin-top: 2rem;
}

.Verivication-Detail .Recommandation .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Verivication-Detail .Recommandation .font-medium {
  font-weight: 500;
}

.Verivication-Detail .Recommandation .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Verivication-Detail .Recommandation .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Verivication-Detail .Recommandation .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Verivication-Detail .Recommandation .bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

.Verivication-Detail .Recommandation .border-transparent {
  border-color: transparent;
}

.Verivication-Detail .Recommandation .border {
  border-width: 1px;
}

.Verivication-Detail .Recommandation .rounded-md {
  border-radius: 0.375rem;
}

.Verivication-Detail .Recommandation .items-center {
  align-items: center;
}

.Verivication-Detail .Recommandation .inline-flex {
  display: inline-flex;
}

.Verivication-Detail .Recommandation button,
[role="button"] {
  cursor: pointer;
}

.Verivication-Detail .Recommandation .grid-center {
  display: grid !important;
  place-items: center !important;
}

/* ---------------------------------- end Recommandation ---------------------------------- */

/* start E_Trainings_Verivication_Detail Témoignage */

:root {
  /* --primary: #0066ff; */
  /* --secondary: #4d94ff; */
  /* --accent: #00ccff; */
  /* --text: #ffffff; */
  /* --bg: #001133; */
}

.Verivication-Detail .Témoignage {
  background: #001133;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  overflow-x: hidden;
}

.Verivication-Detail .Témoignage .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.Verivication-Detail .Témoignage .title {
  font-size: 3.5rem;
  font-weight: 800;
  margin: 2rem 0;
  background: linear-gradient(45deg, #0066ff, #00ccff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: glow 2s ease-in-out infinite alternate;
}

.Verivication-Detail .Témoignage .subtitle {
  font-size: 2rem;
  color: #00ccff;
  margin-bottom: 3rem;
  font-weight: 300;
}

.Verivication-Detail .Témoignage .video-container {
  position: relative;
  width: 80%;
  max-width: 800px;
  margin: 0 auto 3rem;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 102, 255, 0.3);
}

.Verivication-Detail .Témoignage .play-button {
  position: absolute;
  width: 100px;
  height: 100px;
  background: #0066ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 2rem auto;
  transition: all 0.3s ease;
}

.Verivication-Detail .Témoignage .play-button:hover {
  background: #00ccff;
  box-shadow: 0 0 30px rgba(0, 204, 255, 0.5);
}

.Verivication-Detail .Témoignage .play-button::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid white;
  margin-left: 8px;
}

.Verivication-Detail .Témoignage .testimonial-text {
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: rgba(0, 102, 255, 0.1);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 102, 255, 0.1);
}

.Verivication-Detail .Témoignage .author {
  font-size: 1.5rem;
  color: #00ccff;
  margin-top: 1rem;
  font-weight: 600;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px rgba(0, 102, 255, 0.5),
      0 0 20px rgba(0, 102, 255, 0.3), 0 0 30px rgba(0, 102, 255, 0.2);
  }
  to {
    text-shadow: 0 0 20px rgba(0, 102, 255, 0.8),
      0 0 30px rgba(0, 102, 255, 0.6), 0 0 40px rgba(0, 102, 255, 0.4);
  }
}

.Verivication-Detail .Témoignage .waves {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url('data:image/svg+xml,<svg viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg"><path fill="%230066ff" fill-opacity="0.2" d="M0,32L48,37.3C96,43,192,53,288,80C384,107,480,149,576,154.7C672,160,768,128,864,112C960,96,1056,96,1152,106.7C1248,117,1344,139,1392,149.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>')
    repeat-x;
  animation: wave 10s linear infinite;
  z-index: -1;
}

@keyframes wave {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1440px;
  }
}

@media (max-width: 768px) {
  .Verivication-Detail .Témoignage .title {
    font-size: 2rem;
  }
  .Verivication-Detail .Témoignage .subtitle {
    font-size: 1.5rem;
  }
  .Verivication-Detail .Témoignage .video-container {
    width: 95%;
  }
  .Verivication-Detail .Témoignage .play-button {
    width: 70px;
    height: 70px;
  }
}

/* end E_Trainings_Verivication_Detail Témoignage */
