/* Admin Menu */
.admin-menu {
  background: #fff;
  height: 100%;
  scrollbar-width: none;
  overflow: scroll;
  padding-left: 30px;
  padding-top: 32px;
  padding-right: 22px;
  transition: all 0.3s ease;
  min-width: 300px;
  overflow-y: auto;
  max-height: 100%;
  padding-bottom: 120px;
  transform: translateY(-6px);
}

.admin-menu__title {
  font-size: 20px;
  margin-bottom: 12px;
}

.admin-menu .logo {
  border-bottom: 1px solid rgba(243, 245, 249, 0.1);
  padding-bottom: 15px;
  margin-bottom: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.author {
  display: flex;
  border-bottom: 1px solid rgba(243, 245, 249, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.author .author-title {
  margin-left: 15px;
  margin-top: 10px;
}
.author .author-title h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}
.author .author-title p {
  font-size: 13px;
  line-height: 18px;
  color: #ffffff82;
}
.menu-bar li {
  margin-top: 4px;
  position: relative;
  transition: all 0.3s ease;
  /* height: 52px; */
}
.menu-bar__name {
  position: relative;
  top: 2px;
  display: flex;
  align-items: center;
}
.menu-bar li .menu-bar__text i {
  transition: all 0.3s ease;
}
.menu-bar li:hover .menu-bar__name,
.menu-bar li.active .menu-bar__name {
  color: #5356fb;
}
.menu-bar li.active-icon:before {
  opacity: 1;
  visibility: visible;
}
.menu-bar li.show-dropdown:before {
  content: "\f106";
}
.menu-bar li a {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #878f9a;
  padding: 10px 0px;
  display: inline-block;
  width: 100%;
  font-family: "ProductSansRegular";
}
.menu-bar li ul li a {
  color: #fff;
  font-size: 14px;
  padding: 10px 20px;
}
.menu-bar li ul li a img {
  max-width: 17px;
}
.menu-bar li ul {
  padding-left: 20px;
  opacity: 0;
  visibility: hidden;
  height: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menu-bar li.show-dropdown ul {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.menu-bar__text {
  position: relative;
  display: flex;
}
.menu-bar__count {
  position: absolute;
  top: -5px;
  min-width: 20px;
  min-height: 20px;
  line-height: 20px;
  padding: 0;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  font-size: 13px;
  right: -30px;
}
.menu-bar__text .nftmax-menu-icon {
  margin-right: 14px;
  width: 32px;
  height: 32px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  background: rgba(83, 86, 251, 0.16);
  transition: all 0.3s ease;
  color: #374557;
  display: grid;
  place-content: center;
}
.menu-bar li:hover .menu-bar__text .nftmax-menu-icon,
.menu-bar li.active .menu-bar__text .nftmax-menu-icon {
  background: #5356fb;
}
.menu-bar__text .nftmax-menu-icon .nftmax-svg-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  color: #374557;
}
.menu-bar li:hover .nftmax-menu-icon .nftmax-svg-icon,
.menu-bar li.active .nftmax-menu-icon .nftmax-svg-icon {
  fill: #fff;
}

.menu-bar__text .nftmax-menu-icon.nftmax-svg-icon__v3 .nftmax-svg-icon {
  width: 13px;
  height: 14px;
}

.menu-bar__text .nftmax-menu-icon.nftmax-svg-icon__v5 .nftmax-svg-icon {
  width: 15px;
  height: 15px;
}
.menu-bar__text .nftmax-menu-icon.nftmax-svg-icon__v7 .nftmax-svg-icon,
.menu-bar__text .nftmax-menu-icon.nftmax-svg-icon__v8 .nftmax-svg-icon {
  width: 14px;
  height: 13px;
}
.menu-bar__text .nftmax-menu-icon.nftmax-svg-icon__v9 .nftmax-svg-icon {
  width: 19px;
  height: 19px;
}
.menu-bar__text .nftmax-menu-icon.nftmax-svg-icon__v10 .nftmax-svg-icon {
  width: 15px;
  height: 20px;
}
.menu-bar__text .nftmax-menu-icon.nftmax-svg-icon__v11 .nftmax-svg-icon {
  width: 14px;
  height: 16px;
}
.menu-bar img {
  margin-right: 10px;
}
.logout-button {
  margin-top: 36px;
  max-width: 155px;
}
.logout-button .primary {
  padding: 5px 10px;
  background: #5356fb;
  border-radius: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}
.logout-button .primary .menu-bar__name {
  font-family: "ProductSansBold";
  font-weight: 700;
}
.logout-button .primary:hover {
  background-color: #101324;
}
.logo-button__icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  text-align: center;
  border-radius: 100%;
  text-align: center;
  border: 1px solid transparent;
  display: grid;
  place-content: center;
}

.nftmax-scolor {
  color: #5356fb;
}

.close-icon {
  text-align: center;
  min-width: 25px;
  cursor: pointer;
  display: block;
  line-height: initial;
  position: relative;
  margin-left: 30px;
}

.menu-bar__one {
  padding: 0px;
}

/* dashboard banner */
.dashboard-banner {
  padding: 30px;
  border-radius: 15px;
  min-height: 444px;
}

.mg-top-40 {
  margin-top: 40px;
}

.dashboard-banner__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-banner__column.dashboard-banner__column--one {
  flex: 1 1;
  max-width: 390px;
}

.dashboard-banner__content {
  padding-bottom: 14px;
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .dashboard-banner__title {
    font-size: 25px;
  }
}

.dashboard-banner__title {
  color: #fff;
  font-size: 24px;
  margin-bottom: 0;
  letter-spacing: 0.025em;
}

.dashboard-banner__text {
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.025em;
}

.nftmax-header__author-title--two {
  color: #fff;
  margin-bottom: 1px;
  letter-spacing: 0.025em;
}

.nftmax-header__author-text--two span {
  color: #fff;
}

.dashboard-banner__bid {
  display: flex;
  border: 1px solid #ffffff29;
  border-radius: 15px;
  color: #fff;
  justify-content: space-evenly;
  position: relative;
  padding: 24px;
  height: 8rem;
  justify-content: space-evenly;
}

.dashboard-banner__group-small {
  color: #fff;
  line-height: 18px;
  margin: 0;
  letter-spacing: 0.025em;
}

.dashboard-banner__group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .dashboard-banner__group-title {
    font-size: 22px;
  }
}

.dashboard-banner__group-title {
  color: #fff;
  font-size: 24px;
  margin: 0;
  letter-spacing: 0.025em;
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .dashboard-banner__heart {
    width: 46px;
    height: 46px;
    line-height: 46px;
  }
}

.dashboard-banner__heart {
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #fff;
  border-radius: 100%;
  font-size: 25px;
  color: #374557;
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .dashboard-banner__button {
    margin-top: 15px;
  }
}

.dashboard-banner__button {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 30px;
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .nftmax-btn {
    font-size: 16px;
  }
}

.nftmax-btn.nftmax-btn__secondary {
  background: #f539f8;
}

.nftmax-btn.radius {
  border-radius: 50px;
}

.nftmax {
  padding: 9px 23px;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3 linear;
  transition: all 0.3 linear;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  position: relative;
  background: linear-gradient(
    134.38deg,
    #f539f8 0%,
    #c342f9 43.55%,
    #5356fb 104.51%
  );
  font-size: 16px;
  color: #fff;
}
.nftmax-btn:hover,
.nftmax-btn:active,
.nftmax-btn:hover {
  color: #fff;
}

.bg-transparent {
  background: transparent !important;
}

.dashboard-banner__main-btn .nftmax-btn.nftmax-btn__secondary {
  padding: 10px 21px;
}

.nftmax-header__author-text {
  margin: 0;
  line-height: initial;
}

.nftmax-header__author.nftmax-header__author-two {
  margin-bottom: 30px;
}

.nftmax-header__author {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
}

.dashboard-banner__bid {
  display: flex;
  border: 1px solid #ffffff29;
  border-radius: 15px;
  color: #fff;
  justify-content: space-evenly;
  position: relative;
  padding: 24px;
  height: 8rem;
  justify-content: space-evenly;
}

@media only screen and (max-width: 1550px) {
  .dashboard-banner__button {
    gap: 15px;
  }
}

.dashboard-banner__column.dashboard-banner__column--two {
  min-width: 500px;
  width: 500px;
  margin-left: 40px;
}

.dashboard-banner__heart {
  cursor: pointer;
}

.dashboard-banner__heart:hover {
  color: #f539f8;
}

.swiper-slide {
  padding-bottom: 40px;
}

.dashboard-banner__single-slider {
  border-radius: 12px !important;
}

.swiper-pagination-bullet {
  border: 1.5px solid #fff !important;
  background: transparent !important;
  padding: 5px !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 1.5px solid #fff !important;
  background: #fff !important;
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .nftmax-header,
  .nftmax-adashboard {
    padding-left: 314px;
    padding-right: 30px;
  }
}

.nftmax-adashboard {
  width: 100%;
  padding-bottom: 50px;
}

@media only screen and (min-width: 1279px) and (max-width: 1550px) {
  .nftmax-smenu {
    width: 280px;
  }
}

.nftmax-smenu,
.nftmax-adashboard,
.nftmax-header {
  transition: all 0.4s;
}

.nftmax-smenu {
  position: fixed;
  left: 0;
  z-index: 6000;
  height: 100%;
  transition: all 0.3s ease;
  /* width: 335px; */
  /* transform: translate(0%, 50%); */
  box-shadow: 0 9px 95px 0 #0000000d;
}

.nftmax-smenu.nftmax-close {
  transform: translate(0%, -24px);
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .nftmax-smenu {
    width: 280px !important;
    transform: translate(-110%, -54px);
  }
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .nav-header {
    display: block !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .nftmax-adashboard,
  .nftmax-header {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .nftmax-adashboard,
  .nftmax-header {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .dashboard-banner {
    padding: 20px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .nftmax-adashboard,
  .nftmax-header {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .dashboard-banner {
    padding: 20px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .dashboard-banner__main {
    flex-direction: column;
    gap: 15px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .dashboard-banner__column.dashboard-banner__column--one {
    flex: initial;
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .dashboard-banner__column.dashboard-banner__column--two {
    margin: 0;
    width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 575px) {
  .swiper-pagination {
    display: block !important;
  }
}

@media (max-width: 575px) {
  .swiper-pagination {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1278px) {
  .dashboard-banner {
    padding: 20px;
  }
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-1ygil4i-MuiToolbar-root {
  display: flex;
  justify-content: end;
}

.nftmax-smenu button.active,
.nftmax-smenu button:hover {
  background-color: rgb(31 41 55);
}

.nftmax-smenu button.active {
  border: none;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
