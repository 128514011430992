/* .CourseSingle .pageheader-section .title {
  -webkit-text-fill-color: #0000;
  background: linear-gradient(45deg, #2563eb, #4f46e5);
  -webkit-background-clip: text;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.CourseSingle .pageheader-section h1 {
  font-size: 60px;
  line-height: 70px;
}

.CourseSingle .pageheader-section .header {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  position: relative;
}

.CourseSingle .pageheader-section .header:after {
  background: linear-gradient(90deg, #2563eb, #0000);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

.CourseSingle .pageheader-section .sessions-container {
  grid-gap: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.CourseSingle .pageheader-section .session-card {
  background: #fff;
  background: var(--card);
  border-radius: 20px;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  transition: all 0.3s ease;
}

.CourseSingle .pageheader-section .session-card:before {
  background: linear-gradient(90deg, #2563eb, #4f46e5);
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.CourseSingle .pageheader-section .session-badge {
  align-items: center;
  background: #2563eb1a;
  border-radius: 50px;
  color: #2563eb;
  display: inline-flex;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
}

.CourseSingle .pageheader-section .session-info {
  margin-bottom: 1.5rem;
}

.CourseSingle .pageheader-section .info-item {
  align-items: center;
  color: #64748b;
  display: flex;
  font-size: 0.95rem;
  margin-bottom: 0.75rem;
}

.CourseSingle .pageheader-section .info-item i {
  color: #2563eb;
  margin-right: 0.75rem;
}

.CourseSingle .pageheader-section .session-seats {
  align-items: center;
  color: #64748b;
  display: flex;
  font-size: 0.9rem;
  gap: 0.5rem;
}

.CourseSingle .pageheader-section .seats-indicator {
  background: #22c55e;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.CourseSingle .pageheader-section .progress-bar {
  background: #e2e8f0;
  border-radius: 3px;
  height: 6px;
  margin-top: 1rem;
  overflow: hidden;
  width: 100%;
}

.CourseSingle .pageheader-section .progress {
  background: linear-gradient(90deg, #2563eb, #4f46e5);
  border-radius: 3px;
  height: 100%;
  transition: width 0.3s ease;
  width: 70%;
}

.CourseSingle .pageheader-section .session-badge.success {
  background: #22c55e1a;
  color: #22c55e;
}

.CourseSingle .course-single-section .section-title {
  -webkit-text-fill-color: #0000;
  background: linear-gradient(45deg, #2563eb, #4f46e5);
  -webkit-background-clip: text;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  position: relative;
}

.CourseSingle .objectives-grid {
  grid-gap: 1.5rem;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 3rem;
}

.CourseSingle .objective-card {
  background: #fff;
  background: var(--card);
  border: 1px solid #0000001a;
  border-radius: 15px;
  box-shadow: 0 4px 20px #0000000d;
  overflow: hidden;
  padding: 1.5rem;
  position: relative;
  transition: all 0.3s ease;
}

.CourseSingle .objective-card:before {
  color: #22c55e;
  content: "✓";
  font-weight: 700;
  left: 0.7rem;
  position: absolute;
  top: 1rem;
}

.CourseSingle .objective-card:hover {
  box-shadow: 0 8px 25px #0000001a;
  transform: translateY(-5px);
}

.CourseSingle .program-list {
  grid-gap: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 3rem;
}

.CourseSingle .program-item {
  align-items: center;
  background: #fff;
  background: var(--card);
  border-radius: 12px;
  cursor: pointer !important;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  transition: all 0.3s ease;
} */
/* ------------------------------------------------------------------ */
.CourseSingle .to-indigo-50 {
  --tw-gradient-to: #eef2ff var(--tw-gradient-to-position);
}

.CourseSingle .from-blue-50 {
  --tw-gradient-from: #eff6ff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.CourseSingle .bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.CourseSingle .min-h-screen {
  min-height: 100vh;
}

.CourseSingle .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.CourseSingle .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.CourseSingle .overflow-hidden {
  overflow: hidden;
}

.CourseSingle .relative {
  position: relative;
}

.CourseSingle .inset-0 {
  inset: 0px;
}

.CourseSingle .absolute {
  position: absolute;
}

.CourseSingle .opacity-20 {
  opacity: 0.2;
}

.CourseSingle .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.CourseSingle .w-full {
  width: 100%;
}

.CourseSingle .h-full {
  height: 100%;
}

.CourseSingle img,
video {
  max-width: 100%;
  height: auto;
}

.CourseSingle img,
.CourseSingle svg,
.CourseSingle video,
.CourseSingle canvas,
.CourseSingle audio,
.CourseSingle iframe,
.CourseSingle embed,
.CourseSingle object {
  display: block;
  vertical-align: middle;
}

@media (min-width: 1024px) {
  .CourseSingle .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.CourseSingle .py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.CourseSingle .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.CourseSingle .max-w-7xl {
  max-width: 80rem;
}

.CourseSingle .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.CourseSingle .relative {
  position: relative;
}

.CourseSingle .text-center {
  text-align: center;
}

@media (min-width: 1024px) {
  .CourseSingle .lg\:text-6xl {
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }
}

@media (min-width: 640px) {
  .CourseSingle .sm\:text-5xl {
    font-size: 3rem !important;
    line-height: 1 !important;
  }
}

.CourseSingle .tracking-tight {
  letter-spacing: -0.025em;
}

.CourseSingle .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.CourseSingle blockquote,
.CourseSingle dl,
.CourseSingle dd,
.CourseSingle h1,
.CourseSingle h2,
.CourseSingle h3,
.CourseSingle h4,
.CourseSingle h5,
.CourseSingle h6,
.CourseSingle hr,
.CourseSingle figure,
.CourseSingle p,
.CourseSingle pre {
  margin: 0;
}

.CourseSingle h1,
.CourseSingle h2,
.CourseSingle h3,
.CourseSingle h4,
.CourseSingle h5,
.CourseSingle h6 {
  font-size: inherit;
  font-weight: inherit;
}

.CourseSingle .text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.CourseSingle .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.CourseSingle .mt-4 {
  margin-top: 1rem;
}

.CourseSingle .py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.CourseSingle .shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.CourseSingle .rounded-2xl {
  border-radius: 1rem;
}

.CourseSingle .overflow-hidden {
  overflow: hidden;
}

.CourseSingle .justify-center {
  justify-content: center;
}

.CourseSingle .items-center {
  align-items: center;
}

.CourseSingle .flex {
  display: flex;
}

.CourseSingle .opacity-80 {
  opacity: 0.8;
}

.CourseSingle .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.CourseSingle .w-20 {
  width: 5rem;
}

.CourseSingle .h-20 {
  height: 5rem;
}

.CourseSingle img,
.CourseSingle svg,
.CourseSingle video,
.CourseSingle canvas,
.CourseSingle audio,
.CourseSingle iframe,
.CourseSingle embed,
.CourseSingle object {
  display: block;
  vertical-align: middle;
}

.CourseSingle .opacity-50 {
  opacity: 0.5;
}

.CourseSingle .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.CourseSingle .w-full {
  width: 100%;
}

.CourseSingle .h-full {
  height: 100%;
}

.CourseSingle img,
video {
  max-width: 100%;
  height: auto;
}

.CourseSingle img,
.CourseSingle svg,
.CourseSingle video,
.CourseSingle canvas,
.CourseSingle audio,
.CourseSingle iframe,
.CourseSingle embed,
.CourseSingle object {
  display: block;
  vertical-align: middle;
}

.CourseSingle .bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.CourseSingle .py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (min-width: 768px) {
  .CourseSingle .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.CourseSingle .gap-8 {
  gap: 2rem;
}

.CourseSingle .grid {
  display: grid;
}

.CourseSingle .duration-300 {
  transition-duration: 300ms;
}

.CourseSingle .transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.CourseSingle .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.CourseSingle .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.CourseSingle .rounded-xl {
  border-radius: 0.75rem;
}

.CourseSingle .overflow-hidden {
  overflow: hidden;
}

.CourseSingle .p-6 {
  padding: 1.5rem;
}

.CourseSingle .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.CourseSingle .font-semibold {
  font-weight: 600;
}

.CourseSingle .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.CourseSingle blockquote,
.CourseSingle dl,
.CourseSingle dd,
.CourseSingle h1,
.CourseSingle h2,
.CourseSingle h3,
.CourseSingle h4,
.CourseSingle h5,
.CourseSingle h6,
.CourseSingle hr,
.CourseSingle figure,
.CourseSingle p,
.CourseSingle pre {
  margin: 0;
}

.CourseSingle h1,
.CourseSingle h2,
.CourseSingle h3,
.CourseSingle h4,
.CourseSingle h5,
.CourseSingle h6 {
  font-size: inherit;
  font-weight: inherit;
}

.CourseSingle .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.CourseSingle .items-center {
  align-items: center;
}

.CourseSingle .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.CourseSingle .w-5 {
  width: 1.25rem;
}

.CourseSingle .h-5 {
  height: 1.25rem;
}

.CourseSingle .mr-3 {
  margin-right: 0.75rem;
}

.CourseSingle img,
.CourseSingle svg,
.CourseSingle video,
.CourseSingle canvas,
.CourseSingle audio,
.CourseSingle iframe,
.CourseSingle embed,
.CourseSingle object {
  display: block;
  vertical-align: middle;
}

.CourseSingle .mt-6 {
  margin-top: 1.5rem;
}

.CourseSingle .gap-2 {
  gap: 0.5rem;
}

.CourseSingle .flex-wrap {
  flex-wrap: wrap;
}

.CourseSingle .text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.CourseSingle .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.CourseSingle .py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.CourseSingle .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.CourseSingle .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.CourseSingle .rounded-full {
  border-radius: 9999px;
}

.CourseSingle .hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.CourseSingle .py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.CourseSingle .text-title {
  font-size: 2.25rem !important;
  line-height: 1.2 !important;
  letter-spacing: -0.01em !important;
}

.CourseSingle .mb-16 {
  margin-bottom: 4rem;
}

@media (min-width: 1024px) {
  .CourseSingle .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.CourseSingle .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.CourseSingle .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.CourseSingle .p-8 {
  padding: 2rem;
}

.CourseSingle .items-start {
  align-items: flex-start;
}

.CourseSingle .flex-shrink-0 {
  flex-shrink: 0;
}

.CourseSingle .w-6 {
  width: 1.5rem;
}

.CourseSingle .h-6 {
  height: 1.5rem;
}

.CourseSingle .mt-1 {
  margin-top: 0.25rem;
}

.CourseSingle .space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.CourseSingle .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.CourseSingle .leading-relaxed {
  line-height: 1.625;
}

.CourseSingle .font-medium {
  font-weight: 500 !important;
}

.CourseSingle .text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.CourseSingle p {
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.CourseSingle .text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.CourseSingle .hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.CourseSingle .bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.CourseSingle .max-w-3xl {
  max-width: 48rem;
}

.CourseSingle .mb-12 {
  margin-bottom: 3rem;
}

.CourseSingle .font-bold {
  font-weight: 700 !important;
}

.CourseSingle .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.CourseSingle .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.CourseSingle .border {
  border-width: 1px;
}

.CourseSingle .rounded-lg {
  border-radius: 0.5rem;
}

.CourseSingle .block {
  display: block;
}

.CourseSingle .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.CourseSingle .text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
