/* ------------------- start Formation_Participant_Course --------------------------- */
.Formation_Participant_Course .min-h-screen {
  min-height: 100vh;
}

.Formation_Participant_Course .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Formation_Participant_Course .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.Formation_Participant_Course .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.Formation_Participant_Course .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Formation_Participant_Course .max-w-7xl {
  max-width: 80rem;
}

.Formation_Participant_Course .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.Formation_Participant_Course .gap-6 {
  gap: 1.5rem;
}

.Formation_Participant_Course .grid {
  display: grid;
}

.Formation_Participant_Course .transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Formation_Participant_Course .shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Formation_Participant_Course .overflow-hidden {
  overflow: hidden;
}

.Formation_Participant_Course .h-48 {
  height: 12rem;
}

.Formation_Participant_Course .relative {
  position: relative;
}

.Formation_Participant_Course .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.Formation_Participant_Course .w-full {
  width: 100%;
}

.Formation_Participant_Course .h-full {
  height: 100%;
}

.Formation_Participant_Course .top-2 {
  top: 0.5rem;
}

.Formation_Participant_Course .right-2 {
  right: 0.5rem;
}

.Formation_Participant_Course .absolute {
  position: absolute;
}

.Formation_Participant_Course .w-5 {
  width: 1.25rem;
}

.Formation_Participant_Course .h-5 {
  height: 1.25rem;
}

.Formation_Participant_Course .p-4 {
  padding: 1rem;
}

.Formation_Participant_Course .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.Formation_Participant_Course .font-semibold {
  font-weight: 600 !important;
}

.Formation_Participant_Course .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.Formation_Participant_Course .mb-2 {
  margin-bottom: 0.5rem;
}

.Formation_Participant_Course .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.Formation_Participant_Course .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Formation_Participant_Course .items-center {
  align-items: center;
}

.Formation_Participant_Course .flex {
  display: flex;
}

.Formation_Participant_Course .mb-4 {
  margin-bottom: 1rem;
}

.Formation_Participant_Course .mr-2 {
  margin-right: 0.5rem;
}

.Formation_Participant_Course .pt-4 {
  padding-top: 1rem;
}

.Formation_Participant_Course .border-t {
  border-top-width: 1px;
}

.Formation_Participant_Course .justify-between {
  justify-content: space-between;
}

.Formation_Participant_Course .text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.Formation_Participant_Course button,
[role="button"] {
  cursor: pointer;
}

.Formation_Participant_Course .hover\:text-sky-700:hover {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

.Formation_Participant_Course .mt-4 {
  margin-top: 1rem;
}

.Formation_Participant_Course .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.Formation_Participant_Course .tracking-wider {
  letter-spacing: 0.05em;
}

.Formation_Participant_Course .uppercase {
  text-transform: uppercase;
}

.Formation_Participant_Course .font-medium {
  font-weight: 500;
}

.Formation_Participant_Course .text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.Formation_Participant_Course .ml-2 {
  margin-left: 0.5rem;
}

.Formation_Participant_Course .h-4 {
  height: 1rem;
}

.Formation_Participant_Course .mr-1 {
  margin-right: 0.25rem;
}

.Formation_Participant_Course .w-4 {
  width: 1rem;
}

.Formation_Participant_Course .space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.Formation_Participant_Course .hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
/* ------------------- end Formation_Participant_Course ----------------------------- */

/* ------------------- start Formation_Participant_Course_Details ------------------------ */
.Formation_Participant_Course_Details .profile-section {
  padding: 2rem 0;
  background: #fff;
  margin: 2rem 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.Formation_Participant_Course_Details .profile-container {
  display: flex;
  gap: 2.5rem;
  align-items: flex-start;
  padding: 0 2rem;
}

.Formation_Participant_Course_Details .profile-image {
  width: 280px;
  height: 320px;
  border-radius: 4px;
  overflow: hidden;
}

.Formation_Participant_Course_Details .profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Formation_Participant_Course_Details .profile-info {
  flex: 1;
  padding-top: 1rem;
}

.Formation_Participant_Course_Details .profile-info h1 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.Formation_Participant_Course_Details .profile-info .title {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.Formation_Participant_Course_Details .profile-details {
  display: flex;
  gap: 2rem;
  margin: 1.5rem 0;
  color: #4a6ee0;
}

.Formation_Participant_Course_Details .detail-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Curriculum Section */
.Formation_Participant_Course_Details .curriculum {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.Formation_Participant_Course_Details .curriculum h2 {
  color: #333;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.Formation_Participant_Course_Details .lesson-card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.Formation_Participant_Course_Details .lesson-card:hover {
  background: #f1f3f5;
  transform: translateX(5px);
}

.Formation_Participant_Course_Details .lesson-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.Formation_Participant_Course_Details .lesson-number {
  background: #4a6ee0;
  color: white;
  width: 35px;
  height: 35px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.Formation_Participant_Course_Details .lesson-actions {
  display: flex;
  gap: 1rem;
}

.Formation_Participant_Course_Details .action-btn {
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.Formation_Participant_Course_Details .video-btn {
  background: #ff6b6b;
  color: white;
}

.Formation_Participant_Course_Details .pdf-btn {
  background: #51cf66;
  color: white;
}

.Formation_Participant_Course_Details .qcm-btn {
  background: #4a6ee0;
  color: white;
}

.Formation_Participant_Course_Details .action-btn:hover {
  transform: scale(1.05);
  opacity: 0.95;
}

@media (max-width: 768px) {
  .Formation_Participant_Course_Details .profile-container {
    flex-direction: column;
    align-items: center;
  }

  .Formation_Participant_Course_Details .profile-image {
    width: 100%;
    max-width: 280px;
  }

  .Formation_Participant_Course_Details .profile-info {
    text-align: center;
  }

  .Formation_Participant_Course_Details .profile-details {
    justify-content: center;
    flex-wrap: wrap;
  }

  .Formation_Participant_Course_Details .lesson-card {
    flex-direction: column;
    gap: 1rem;
  }

  .Formation_Participant_Course_Details .lesson-actions {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Formation_Participant_Course_Details .lesson-card {
  animation: fadeIn 0.5s ease-out forwards;
}

@media (max-width: 1399px) {
  .Formation_Participant_Course_Details .xl\:py-\[80px\] {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}

@media (max-width: 991px) {
  .Formation_Participant_Course_Details .md\:py-\[60px\] {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.Formation_Participant_Course_Details .border {
  border-width: 1px !important;
}

.Formation_Participant_Course_Details .flex {
  display: flex !important
;
}

.Formation_Participant_Course_Details .p-\[20px\] {
  padding: 20px;
}

.Formation_Participant_Course_Details .border-\[\#E5E5E5\] {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.Formation_Participant_Course_Details .rounded-\[10px\] {
  border-radius: 10px;
}

.Formation_Participant_Course_Details .overflow-auto {
  overflow: auto;
}

.Formation_Participant_Course_Details .gap-\[16px\] {
  gap: 16px;
}

.Formation_Participant_Course_Details .flex {
  display: flex;
}

.Formation_Participant_Course_Details .overflow-auto {
  overflow: auto !important;
}

.Formation_Participant_Course_Details
  .ed-course-details-tab-navs
  button.active {
  background-color: #5b3aee;
  color: #fff;
}

.Formation_Participant_Course_Details [type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.Formation_Participant_Course_Details .\*\:font-semibold > * {
  font-weight: 600;
}

.Formation_Participant_Course_Details .\*\:px-\[20px\] > * {
  padding-left: 20px;
  padding-right: 20px;
}

.Formation_Participant_Course_Details .\*\:bg-edpurple\/\[06\%\] > * {
  background-color: #5b3aee0f;
}

.Formation_Participant_Course_Details .\*\:rounded-\[8px\] > * {
  border-radius: 8px;
}

.Formation_Participant_Course_Details .\*\:flex-auto > * {
  flex: 1 1 auto;
}

.Formation_Participant_Course_Details .\*\:h-\[56px\] > * {
  height: 56px;
}

.Formation_Participant_Course_Details [role="button"],
button {
  cursor: pointer;
}

.Formation_Participant_Course_Details button,
.Formation_Participant_Course_Details select {
  text-transform: none;
}

.Formation_Participant_Course_Details button,
.Formation_Participant_Course_Details input,
.Formation_Participant_Course_Details optgroup,
.Formation_Participant_Course_Details select,
.Formation_Participant_Course_Details textarea {
  font-feature-settings: inherit;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-variation-settings: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

.Formation_Participant_Course_Details .items-center {
  align-items: center !important;
}

.Formation_Participant_Course_Details .gap-y-\[15px\] {
  row-gap: 15px;
}

.Formation_Participant_Course_Details .gap-x-\[30px\] {
  column-gap: 30px;
}

.Formation_Participant_Course_Details .mt-\[40px\] {
  margin-top: 40px;
}

.Formation_Participant_Course_Details .rounded-\[12px\] {
  border-radius: 12px;
}

.Formation_Participant_Course_Details .w-\[270px\] {
  width: 270px;
}

.Formation_Participant_Course_Details .aspect-\[74\/75\] {
  aspect-ratio: 74 / 75;
}

.Formation_Participant_Course_Details img,
.Formation_Participant_Course_Details video {
  height: auto;
  max-width: 100%;
}

.Formation_Participant_Course_Details .font-semibold {
  font-weight: 600 !important;
}

.Formation_Participant_Course_Details .text-\[22px\] {
  font-size: 22px;
}

@media (max-width: 1399px) {
  .Formation_Participant_Course_Details .xl\:gap-x-\[20px\] {
    column-gap: 20px;
  }
}

.Formation_Participant_Course_Details .text-\[16px\] {
  font-size: 16px;
}

.Formation_Participant_Course_Details .py-\[18px\] {
  padding-bottom: 18px;
  padding-top: 18px;
}

.Formation_Participant_Course_Details .gap-y-\[10px\] {
  row-gap: 10px;
}

.Formation_Participant_Course_Details .mt-\[21px\] {
  margin-top: 21px;
}

.Formation_Participant_Course_Details .mb-\[20px\] {
  margin-bottom: 20px;
}

.Formation_Participant_Course_Details .flex-wrap {
  flex-wrap: wrap !important;
}

.Formation_Participant_Course_Details .text-\[16px\] {
  font-size: 16px;
}

.Formation_Participant_Course_Details .font-medium {
  font-weight: 500 !important;
}

.Formation_Participant_Course_Details .text-edpurple {
  --tw-text-opacity: 1;
  color: rgb(91 58 238 / var(--tw-text-opacity));
}

.Formation_Participant_Course_Details .font-semibold {
  font-weight: 600 !important;
}

.Formation_Participant_Course_Details .text-edblue {
  --tw-text-opacity: 1;
  color: rgb(36 20 66 / var(--tw-text-opacity));
}

.Formation_Participant_Course_Details .font-semibold {
  font-weight: 600;
}

.Formation_Participant_Course_Details .text-\[20px\] {
  font-size: 20px;
}

.Formation_Participant_Course_Details .mb-\[16px\] {
  margin-bottom: 16px;
}

.Formation_Participant_Course_Details .mt-\[32px\] {
  margin-top: 32px;
}

.Formation_Participant_Course_Details .text-\[20px\] {
  font-size: 20px;
}

.Formation_Participant_Course_Details .lesson-info h3 {
  font-size: 32px;
  font-weight: 700;
}

/* ------------------- end Formation_Participant_Course_Details -------------------------- */
