/* ------------ start navigation ------------*/

.E_Trainings_Certificate_Detai .nav-link {
  animation: fadeInUp 0.5s ease forwards;
  opacity: 0;
}

.E_Trainings_Certificate_Detai .nav-link:nth-child(1) {
  animation-delay: 0.1s;
}

.E_Trainings_Certificate_Detai .nav-link:nth-child(2) {
  animation-delay: 0.2s;
}

.E_Trainings_Certificate_Detai .nav-link:nth-child(3) {
  animation-delay: 0.3s;
}

.Verivication-Detail .header,
.E_Trainings_Certificate_Detai .header {
  background-color: #fff;
  height: 100%;
  overflow: auto;
  padding-bottom: 101px;
  position: fixed;
  width: 412px;
}

@media only screen and (max-width: 991px) {
  .mobile-menu-hide {
    box-shadow: none;
    margin-left: -100%;
  }
}

@media only screen and (max-width: 991px) {
  .mobile-header {
    background-color: #fff;
    border-bottom: 1px solid #f5f5f5;
    height: 50px;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
}
@media only screen and (max-width: 991px) {
  .mobile-visible {
    display: block;
    visibility: visible;
  }
}
.d-block {
  display: block !important;
}

.E_Trainings_Certificate_Detai .css-2kipkr {
  padding: 20px 3px 20px 20px;
}

.E_Trainings_Certificate_Detai .shadow-lg {
  --tw-shadow: 0 4px 15px -3px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 15px -3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.E_Trainings_Certificate_Detai .p-8 {
  padding: 2rem;
}

.E_Trainings_Certificate_Detai .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .rounded-xl {
  border-radius: 0.75rem;
}

.E_Trainings_Certificate_Detai .justify-center {
  justify-content: center;
}

.E_Trainings_Certificate_Detai .items-center {
  align-items: center;
}

.E_Trainings_Certificate_Detai .flex {
  display: flex;
}

.E_Trainings_Certificate_Detai .mb-6 {
  margin-bottom: 1.5rem;
}

.E_Trainings_Certificate_Detai .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .rounded-full {
  border-radius: 9999px;
}

.E_Trainings_Certificate_Detai .justify-center {
  justify-content: center;
}

.E_Trainings_Certificate_Detai .items-center {
  align-items: center;
}

.E_Trainings_Certificate_Detai .w-16 {
  width: 4rem;
}

.E_Trainings_Certificate_Detai .h-16 {
  height: 4rem;
}

.E_Trainings_Certificate_Detai .flex {
  display: flex;
}

.E_Trainings_Certificate_Detai .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.E_Trainings_Certificate_Detai .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.E_Trainings_Certificate_Detai .p-4 {
  padding: 1rem;
}

.E_Trainings_Certificate_Detai .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .rounded-lg {
  border-radius: 0.5rem;
}

.E_Trainings_Certificate_Detai .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1)) !important;
}

/* ------------ end navigation --------------- */

/* ------------ start about --------------- */

.E_Trainings_Certificate_Detai .about .shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.E_Trainings_Certificate_Detai .about .p-8 {
  padding: 2rem;
}

.E_Trainings_Certificate_Detai .about .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.E_Trainings_Certificate_Detai .about .border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

.E_Trainings_Certificate_Detai .about .border-2 {
  border-width: 2px;
}

.E_Trainings_Certificate_Detai .about .rounded-lg {
  border-radius: 0.5rem;
}

.E_Trainings_Certificate_Detai .about .overflow-hidden {
  overflow: hidden;
}

.E_Trainings_Certificate_Detai .about .relative {
  position: relative;
}

.E_Trainings_Certificate_Detai .about .to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.E_Trainings_Certificate_Detai .about .from-blue-50 {
  --tw-gradient-from: #eff6ff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.E_Trainings_Certificate_Detai .about .bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.E_Trainings_Certificate_Detai .about .min-h-screen {
  min-height: 100vh;
}

.E_Trainings_Certificate_Detai .about .opacity-50 {
  opacity: 0.5;
}

.E_Trainings_Certificate_Detai .about .bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.E_Trainings_Certificate_Detai .about .rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.E_Trainings_Certificate_Detai .about .w-32 {
  width: 8rem;
}

.E_Trainings_Certificate_Detai .about .h-32 {
  height: 8rem;
}

.E_Trainings_Certificate_Detai .about .top-0 {
  top: 0px;
}

.E_Trainings_Certificate_Detai .about .left-0 {
  left: 0px;
}

.E_Trainings_Certificate_Detai .about .absolute {
  position: absolute;
}

.E_Trainings_Certificate_Detai .about .rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.E_Trainings_Certificate_Detai .about .rounded-tr-full {
  border-top-right-radius: 9999px;
}

.E_Trainings_Certificate_Detai .about .rounded-tl-full {
  border-top-left-radius: 9999px;
}

.E_Trainings_Certificate_Detai .about .opacity-30 {
  opacity: 0.3;
}

.E_Trainings_Certificate_Detai .about .border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

.E_Trainings_Certificate_Detai .about .border-4 {
  border-width: 4px;
}

.E_Trainings_Certificate_Detai .about .rounded-lg {
  border-radius: 0.5rem;
}

.E_Trainings_Certificate_Detai .about .inset-4 {
  inset: 1rem;
}

.E_Trainings_Certificate_Detai .about .absolute {
  position: absolute;
}

.E_Trainings_Certificate_Detai .about .text-center {
  text-align: center;
}

.E_Trainings_Certificate_Detai .about .mb-8 {
  margin-bottom: 2rem;
}

.E_Trainings_Certificate_Detai .about .justify-center {
  justify-content: center;
}

.E_Trainings_Certificate_Detai .about .items-center {
  align-items: center;
}

.E_Trainings_Certificate_Detai .about .flex {
  display: flex;
}

.E_Trainings_Certificate_Detai .about .mb-4 {
  margin-bottom: 1rem;
}

.E_Trainings_Certificate_Detai .about .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.E_Trainings_Certificate_Detai .about .w-12 {
  width: 3rem;
}

.E_Trainings_Certificate_Detai .about .h-12 {
  height: 3rem;
}

.E_Trainings_Certificate_Detai .about .mr-2 {
  margin-right: 0.5rem;
}

.E_Trainings_Certificate_Detai .about img,
.E_Trainings_Certificate_Detai .about svg,
.E_Trainings_Certificate_Detai .about video,
.E_Trainings_Certificate_Detai .about canvas,
.E_Trainings_Certificate_Detai .about audio,
.E_Trainings_Certificate_Detai .about iframe,
.E_Trainings_Certificate_Detai .about embed,
.E_Trainings_Certificate_Detai .about object {
  display: block;
  vertical-align: middle;
}

.E_Trainings_Certificate_Detai .about .w-16 {
  width: 4rem;
}

.E_Trainings_Certificate_Detai .about .h-16 {
  height: 4rem;
}

.E_Trainings_Certificate_Detai .about .ml-2 {
  margin-left: 0.5rem;
}

.E_Trainings_Certificate_Detai .about .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.E_Trainings_Certificate_Detai .about .font-bold {
  font-weight: 700 !important;
}

.E_Trainings_Certificate_Detai .about .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.E_Trainings_Certificate_Detai .about .mb-2 {
  margin-bottom: 0.5rem;
}

.E_Trainings_Certificate_Detai .about .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.E_Trainings_Certificate_Detai .about .w-32 {
  width: 8rem;
}

.E_Trainings_Certificate_Detai .about .h-1 {
  height: 0.25rem;
}

.E_Trainings_Certificate_Detai .about .mb-4 {
  margin-bottom: 1rem;
}

.E_Trainings_Certificate_Detai .about .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.E_Trainings_Certificate_Detai .about .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.E_Trainings_Certificate_Detai .about .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.E_Trainings_Certificate_Detai .about .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.E_Trainings_Certificate_Detai .about .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.E_Trainings_Certificate_Detai .about .mt-2 {
  margin-top: 0.5rem;
}

.E_Trainings_Certificate_Detai .about .font-semibold {
  font-weight: 600;
}

.E_Trainings_Certificate_Detai .about .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.E_Trainings_Certificate_Detai .about .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.E_Trainings_Certificate_Detai .about .text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.E_Trainings_Certificate_Detai .about .flex-shrink-0 {
  flex-shrink: 0;
}

.E_Trainings_Certificate_Detai .about .w-5 {
  width: 1.25rem;
}

.E_Trainings_Certificate_Detai .about .h-5 {
  height: 1.25rem;
}

.E_Trainings_Certificate_Detai .about .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.E_Trainings_Certificate_Detai
  .about
  .space-x-3
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.E_Trainings_Certificate_Detai
  .about
  .space-x-4
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

/* ------------ end about ----------------- */

/* ------------ start evaluation --------------- */

.E_Trainings_Certificate_Detai .evaluation .text-transparent {
  background: linear-gradient(45deg, #4f46e5, #9333ea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.E_Trainings_Certificate_Detai .evaluation .font-bold {
  font-weight: 700;
}

.E_Trainings_Certificate_Detai .evaluation .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.E_Trainings_Certificate_Detai .evaluation .mb-8 {
  margin-bottom: 2rem;
}

.E_Trainings_Certificate_Detai .evaluation .hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.E_Trainings_Certificate_Detai .evaluation .p-4 {
  padding: 1rem;
}

.E_Trainings_Certificate_Detai .evaluation .rounded-lg {
  border-radius: 0.5rem;
}

.E_Trainings_Certificate_Detai .evaluation .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .rounded-full {
  border-radius: 9999px;
}

.E_Trainings_Certificate_Detai .evaluation .overflow-hidden {
  overflow: hidden;
}

.E_Trainings_Certificate_Detai .evaluation .h-2\.5 {
  height: 0.625rem;
}

.E_Trainings_Certificate_Detai .evaluation .relative {
  position: relative;
}

.E_Trainings_Certificate_Detai .evaluation .shadow-orange-200 {
  --tw-shadow-color: #fed7aa;
  --tw-shadow: var(--tw-shadow-colored);
}

.E_Trainings_Certificate_Detai .evaluation .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.E_Trainings_Certificate_Detai .evaluation .bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .rounded-full {
  border-radius: 9999px;
}

.E_Trainings_Certificate_Detai .evaluation .h-full {
  height: 100%;
}

.E_Trainings_Certificate_Detai .evaluation .text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .font-medium {
  font-weight: 500;
}

.E_Trainings_Certificate_Detai .evaluation .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.E_Trainings_Certificate_Detai .evaluation .py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.E_Trainings_Certificate_Detai .evaluation .px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.E_Trainings_Certificate_Detai .evaluation .bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .rounded-full {
  border-radius: 9999px;
}

.E_Trainings_Certificate_Detai .evaluation .shadow-yellow-200 {
  --tw-shadow-color: #fef08a;
  --tw-shadow: var(--tw-shadow-colored);
}

.E_Trainings_Certificate_Detai .evaluation .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.E_Trainings_Certificate_Detai .evaluation .bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .shadow-green-200 {
  --tw-shadow-color: #bbf7d0;
  --tw-shadow: var(--tw-shadow-colored);
}

.E_Trainings_Certificate_Detai .evaluation .text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .border-2 {
  border-width: 2px;
}

.E_Trainings_Certificate_Detai .evaluation .text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.E_Trainings_Certificate_Detai .evaluation .bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .shadow-red-200 {
  --tw-shadow-color: #fecaca;
  --tw-shadow: var(--tw-shadow-colored);
}

.E_Trainings_Certificate_Detai .evaluation .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.E_Trainings_Certificate_Detai .evaluation .bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .shadow-emerald-200 {
  --tw-shadow-color: #a7f3d0;
  --tw-shadow: var(--tw-shadow-colored);
}

.E_Trainings_Certificate_Detai .evaluation .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.E_Trainings_Certificate_Detai .evaluation .bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity, 1));
}

.E_Trainings_Certificate_Detai .evaluation .bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity, 1));
}

/* ------------ end evaluation ----------------- */

/* ------------ start gallery --------------- */

.E_Trainings_Certificate_Detai .gallery header {
  padding: 11px 49px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  background: var(--primary-color) !important;
  color: white !important;
  height: auto;
  position: absolute;
  z-index: 1;
}

.E_Trainings_Certificate_Detai .gallery .intro-section {
  background: linear-gradient(45deg, #f6f6f6, #ffffff);
}

.E_Trainings_Certificate_Detai .gallery .profile-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 300px;
}

.E_Trainings_Certificate_Detai .gallery .profile-image:hover {
  transform: scale(1.02);
}

.E_Trainings_Certificate_Detai .gallery .intro-text h1 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.E_Trainings_Certificate_Detai .gallery .intro-text h1 span {
  color: var(--accent-color-2);
  font-size: 1.5rem;
}

.E_Trainings_Certificate_Detai .gallery .title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.E_Trainings_Certificate_Detai .gallery .formations {
  padding: 4rem 10%;
  background: white;
}

.E_Trainings_Certificate_Detai .gallery .contact-section {
  background: #1a1a1a;
  color: white;
  padding: 4rem 10%;
  text-align: center;
}

.E_Trainings_Certificate_Detai .gallery .signature {
  margin-top: 2rem;
  font-family: "Dancing Script", cursive;
  font-size: 2rem;
  color: #40e0d0;
}

/* ------------ end gallery --------------- */

.E_Trainings_Certificate_Detai .letter-container {
  max-width: 95%;
  margin: 0 auto;
  background: white;
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.E_Trainings_Certificate_Detai .letter-container .header-content {
  position: relative;
  margin-bottom: 3rem;
}

.E_Trainings_Certificate_Detai .title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2d3436;
  text-transform: uppercase;
  margin-bottom: 2rem;
  position: relative;
  letter-spacing: 1px;
}

.E_Trainings_Certificate_Detai .title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #00b894, #00cec9);
  border-radius: 2px;
}

.E_Trainings_Certificate_Detai .participant-name {
  font-size: 1.5rem;
  color: #00b894;
  margin-bottom: 1.5rem;
}

.E_Trainings_Certificate_Detai .label {
  font-size: 0.9rem;
  color: #636e72;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.E_Trainings_Certificate_Detai .company {
  font-size: 1.8rem;
  font-weight: 600;
  color: #2d3436;
  margin-bottom: 1rem;
}

.E_Trainings_Certificate_Detai .date {
  font-size: 1.1rem;
  color: #636e72;
}

.E_Trainings_Certificate_Detai .decorative-element {
  position: absolute;
  top: -50px;
  right: -50px;
  width: 200px;
  height: 200px;
  background: linear-gradient(
    135deg,
    rgba(0, 184, 148, 0.1),
    rgba(0, 206, 201, 0.1)
  );
  border-radius: 50%;
  z-index: 0;
}

.E_Trainings_Certificate_Detai .content {
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .E_Trainings_Certificate_Detai .letter-container {
    padding: 2rem;
  }

  .E_Trainings_Certificate_Detai .title {
    font-size: 2rem;
  }

  .E_Trainings_Certificate_Detai .participant-name {
    font-size: 1.3rem;
  }

  .E_Trainings_Certificate_Detai .company {
    font-size: 1.5rem;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.E_Trainings_Certificate_Detai .letter-container .header-content > * {
  animation: fadeIn 0.8s ease-out forwards;
}

.E_Trainings_Certificate_Detai .title {
  animation-delay: 0.2s;
}

.E_Trainings_Certificate_Detai .participant-name {
  animation-delay: 0.4s;
}

.E_Trainings_Certificate_Detai .company {
  animation-delay: 0.6s;
}

.E_Trainings_Certificate_Detai .date {
  animation-delay: 0.8s;
}

.E_Trainings_Certificate_Detai .formation-card {
  max-width: 95%;
  width: 100%;
  background: white;
  border-radius: 15px;
  padding: 2.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  margin: auto !important;
  margin-top: 11px !important;
}

.E_Trainings_Certificate_Detai .formation-title {
  font-size: 2.2rem;
  font-weight: 800;
  color: #2d3436;
  margin-bottom: 2rem;
  position: relative;
  padding-bottom: 0.5rem;
  letter-spacing: 1px;
}

.E_Trainings_Certificate_Detai .formation-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #00b894, #00cec9);
  border-radius: 2px;
}

.E_Trainings_Certificate_Detai .course-container {
  position: relative;
  padding-left: 2rem;
  border-left: 3px solid #00b894;
  margin-left: 1rem;
}

.E_Trainings_Certificate_Detai .course-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #2d3436;
  margin-bottom: 1rem;
  position: relative;
}

.E_Trainings_Certificate_Detai .course-title::before {
  content: "";
  position: absolute;
  left: -2.6rem;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  background: #00b894;
  border-radius: 50%;
  box-shadow: 0 0 0 5px rgba(0, 184, 148, 0.2);
}

.E_Trainings_Certificate_Detai .course-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #636e72;
  padding-right: 2rem;
}

.E_Trainings_Certificate_Detai .highlight {
  color: #00b894;
  font-weight: 500;
}

.E_Trainings_Certificate_Detai .decorative-circle {
  position: absolute;
  top: -100px;
  right: -100px;
  width: 200px;
  height: 200px;
  background: linear-gradient(
    135deg,
    rgba(0, 184, 148, 0.1),
    rgba(0, 206, 201, 0.1)
  );
  border-radius: 50%;
  z-index: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.E_Trainings_Certificate_Detai .formation-card > * {
  animation: fadeInUp 0.8s ease-out forwards;
}

@media (max-width: 768px) {
  .E_Trainings_Certificate_Detai .formation-card {
    padding: 1.5rem;
  }

  .E_Trainings_Certificate_Detai .formation-title {
    font-size: 1.8rem;
  }

  .E_Trainings_Certificate_Detai .course-title {
    font-size: 1.5rem;
  }

  .E_Trainings_Certificate_Detai .course-description {
    font-size: 1rem;
    padding-right: 0;
  }

  .E_Trainings_Certificate_Detai .course-container {
    padding-left: 1.5rem;
  }
}

/* Hover effects */
.E_Trainings_Certificate_Detai .course-container:hover .course-title::before {
  transform: translateY(-50%) scale(1.2);
  transition: transform 0.3s ease;
}

.E_Trainings_Certificate_Detai .course-container:hover {
  border-left-color: #00cec9;
  transition: border-left-color 0.3s ease;
}

.E_Trainings_Certificate_Detai .recommendation-card {
  max-width: 95%;
  width: 100%;
  background: white;
  border-radius: 15px;
  padding: 3rem;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  margin: auto;
}

.E_Trainings_Certificate_Detai .recommendation-card .content {
  position: relative;
  z-index: 2;
}

.E_Trainings_Certificate_Detai .recommendation-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #2d3436;
  margin-bottom: 2rem;
  position: relative;
  text-align: justify;
}

.E_Trainings_Certificate_Detai .highlight {
  color: #00b894;
  font-weight: 600;
}

.E_Trainings_Certificate_Detai .note {
  margin-top: 2rem;
  padding: 1.5rem;
  background: rgba(0, 184, 148, 0.05);
  border-left: 4px solid #00b894;
  border-radius: 0 10px 10px 0;
}

.E_Trainings_Certificate_Detai .note-text {
  font-size: 0.95rem;
  color: #636e72;
  line-height: 1.6;
}

.E_Trainings_Certificate_Detai .quote-mark {
  position: absolute;
  font-size: 8rem;
  opacity: 0.05;
  top: -2rem;
  left: -2rem;
  font-family: Georgia, serif;
  color: #00b894;
}

.E_Trainings_Certificate_Detai .background-decoration {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 300px;
  background: linear-gradient(
    135deg,
    rgba(0, 184, 148, 0.05),
    rgba(0, 206, 201, 0.05)
  );
  border-radius: 50%;
  transform: translate(50%, -50%);
  z-index: 1;
}

.E_Trainings_Certificate_Detai .competence-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1.5rem 0;
}

.E_Trainings_Certificate_Detai .competence-tag {
  background: rgba(0, 184, 148, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: #00b894;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.E_Trainings_Certificate_Detai .competence-tag:hover {
  background: rgba(0, 184, 148, 0.2);
  transform: translateY(-2px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.E_Trainings_Certificate_Detai .recommendation-card > * {
  animation: fadeIn 0.8s ease-out forwards;
}

@media (max-width: 768px) {
  .E_Trainings_Certificate_Detai .recommendation-card {
    padding: 1.5rem;
  }

  .E_Trainings_Certificate_Detai .recommendation-text {
    font-size: 1rem;
  }

  .E_Trainings_Certificate_Detai .competence-tag {
    font-size: 0.8rem;
  }
}

.E_Trainings_Certificate_Detai .frame-container {
  position: relative;
  max-width: 800px;
  width: 400px;
  padding: 20px;
  border-radius: 30px;
  background: #e0e5ec;
  box-shadow: 20px 20px 60px #bec3c9, -20px -20px 60px #ffffff;
  transition: all 0.3s ease;
}

.E_Trainings_Certificate_Detai .frame-container:hover {
  transform: scale(1.02);
}

.E_Trainings_Certificate_Detai .image-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  overflow: hidden;
  border-radius: 20px;
}

.E_Trainings_Certificate_Detai .main-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  transition: all 0.5s ease;
}

.E_Trainings_Certificate_Detai .frame-container:hover .main-image {
  transform: scale(1.05);
}

.E_Trainings_Certificate_Detai .frame-title {
  text-align: center;
  color: #2d4059;
  margin: 15px 0;
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.E_Trainings_Certificate_Detai .corner {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid #4a90e2;
  transition: all 0.3s ease;
}

.E_Trainings_Certificate_Detai .top-left {
  top: 10px;
  left: 10px;
  border-right: none;
  border-bottom: none;
}

.E_Trainings_Certificate_Detai .top-right {
  top: 10px;
  right: 10px;
  border-left: none;
  border-bottom: none;
}

.E_Trainings_Certificate_Detai .bottom-left {
  bottom: 10px;
  left: 10px;
  border-right: none;
  border-top: none;
}

.E_Trainings_Certificate_Detai .bottom-right {
  bottom: 10px;
  right: 10px;
  border-left: none;
  border-top: none;
}

.E_Trainings_Certificate_Detai .frame-container:hover .corner {
  width: 40px;
  height: 40px;
}

@media (max-width: 768px) {
  .E_Trainings_Certificate_Detai .frame-container {
    padding: 15px;
  }

  .E_Trainings_Certificate_Detai .frame-title {
    font-size: 1.2em;
  }

  .E_Trainings_Certificate_Detai .corner {
    width: 20px;
    height: 20px;
  }
}

.E_Trainings_Certificate_Detai .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(74, 144, 226, 0.2),
    transparent 60%
  );
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.E_Trainings_Certificate_Detai .frame-container:hover .glow {
  opacity: 1;
}

/* ------------ end gallery ----------------- */
