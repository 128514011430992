.AccessPlus.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.AccessPlus.font-sans {
  font-family: Plus Jakarta Sans, sans-serif;
}

.AccessPlus.bg-navy-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 22 39 / var(--tw-bg-opacity));
}

.AccessPlus.overflow-hidden {
  overflow: hidden;
}

.AccessPlus.min-h-screen {
  min-height: 100vh;
}

.AccessPlus.relative {
  position: relative;
}

.AccessPlus .to-primary-900\/80 {
  background: linear-gradient(90deg, rgb(26 34 53 / 0.9), rgb(56 47 137 / 0.8));
}

.AccessPlus .z-10 {
  z-index: 10;
}

.AccessPlus .inset-0 {
  inset: 0px;
}

.AccessPlus .absolute {
  position: absolute;
}

.AccessPlus .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.AccessPlus .w-full {
  width: 100%;
}

.AccessPlus .h-full {
  height: 100%;
}

.AccessPlus .inset-0 {
  inset: 0px;
}

.AccessPlus .absolute {
  position: absolute;
}

.AccessPlus img,
.AccessPlus video {
  max-width: 100%;
  height: auto;
}

.AccessPlus img,
.AccessPlus svg,
.AccessPlus video,
.AccessPlus canvas,
.AccessPlus audio,
.AccessPlus iframe,
.AccessPlus embed,
.AccessPlus object {
  display: block;
  vertical-align: middle;
}

.AccessPlus .py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.AccessPlus .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.AccessPlus .max-w-6xl {
  max-width: 72rem;
}

.AccessPlus .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.AccessPlus .z-20 {
  z-index: 20;
}

.AccessPlus .relative {
  position: relative;
}

@media (min-width: 1024px) {
  .AccessPlus .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.AccessPlus .gap-12 {
  gap: 3rem;
}

.AccessPlus .items-center {
  align-items: center;
}

.AccessPlus .grid {
  display: grid;
}

.AccessPlus .tracking-tight {
  letter-spacing: -0.025em;
}

.AccessPlus .leading-tight {
  line-height: 1.25;
}

.AccessPlus .font-bold {
  font-weight: 700 !important;
}

.AccessPlus .text-5xl {
  font-size: 3rem !important;
  line-height: 1 !important;
}

.AccessPlus .font-heading {
  font-family: Clash Display, sans-serif !important;
}

.AccessPlus .mb-4 {
  margin-bottom: 1rem !important;
}

.AccessPlus blockquote,
.AccessPlus dl,
.AccessPlus dd,
.AccessPlus h1,
.AccessPlus h2,
.AccessPlus h3,
.AccessPlus h4,
.AccessPlus h5,
.AccessPlus h6,
.AccessPlus hr,
.AccessPlus figure,
.AccessPlus p,
.AccessPlus pre {
  margin: 0;
}

.AccessPlus h1,
.AccessPlus h2,
.AccessPlus h3,
.AccessPlus h4,
.AccessPlus h5,
.AccessPlus h6 {
  font-size: inherit;
  font-weight: inherit;
}

@media (min-width: 1024px) {
  .AccessPlus .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.AccessPlus .text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.AccessPlus .tracking-tight {
  letter-spacing: -0.025em;
}

.AccessPlus .font-heading {
  font-family: Clash Display, sans-serif !important;
}

.AccessPlus .mb-8 {
  margin-bottom: 2rem;
}

.AccessPlus .gap-4 {
  gap: 1rem;
}

.AccessPlus .grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.AccessPlus .max-w-xl {
  max-width: 36rem;
}

.AccessPlus .text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(139 143 255 / var(--tw-text-opacity));
}

.AccessPlus .backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}

.AccessPlus .p-8 {
  padding: 2rem;
}

.AccessPlus .bg-white\/5 {
  background-color: rgb(255 255 255 / 0.05);
}

.AccessPlus .border-white\/10 {
  border-color: rgb(255 255 255 / 0.1) !important;
}

.AccessPlus .border {
  border-width: 1px !important;
}

.AccessPlus .rounded-2xl {
  border-radius: 1rem;
}

.AccessPlus .text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

@media (min-width: 1024px) {
  .AccessPlus .lg\:text-6xl {
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }
}

.AccessPlus .text-transparent {
  color: transparent;
}

.AccessPlus .font-semibold {
  font-weight: 600;
}

.AccessPlus .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.AccessPlus .text-center {
  text-align: center;
}

.AccessPlus .bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.AccessPlus .to-primary-200 {
  background: linear-gradient(90deg, #8b8fff, #cbd9ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.AccessPlus .mb-6 {
  margin-bottom: 1.5rem;
}

.AccessPlus .space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.AccessPlus .duration-300 {
  transition-duration: 300ms;
}

.AccessPlus .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.AccessPlus .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.AccessPlus .font-semibold {
  font-weight: 600;
}

.AccessPlus .font-heading {
  font-family: Clash Display, sans-serif;
}

.AccessPlus .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.AccessPlus .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.AccessPlus .to-primary-500 {
  background: linear-gradient(90deg, #5d4af7, #6b66ff);
}

.AccessPlus .rounded-lg {
  border-radius: 0.5rem;
}

.AccessPlus .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.AccessPlus .w-full {
  width: 100%;
}

.AccessPlus button,
[role="button"] {
  cursor: pointer;
}

.AccessPlus button,
.AccessPlus input:where([type="button"]),
.AccessPlus input:where([type="reset"]),
.AccessPlus input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.AccessPlus button,
.AccessPlus select {
  text-transform: none;
}

.AccessPlus button,
.AccessPlus input,
.AccessPlus optgroup,
.AccessPlus select,
.AccessPlus textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.AccessPlus .hover\:to-primary-600:hover {
  background: linear-gradient(90deg, #4e3ad8, #5d4af7);
}
