:root {
  /* --primary-color: #2c3e50; */
  /* --secondary-color: #3498db; */
  /* --accent-color: #e74c3c; */
  /* --background-color: #f5f6fa; */
  /* --card-bg: rgba(255, 255, 255, 0.95); */
  /* --text-primary: #2c3e50; */
  /* --text-secondary: #7f8c8d; */
  /* --border-radius: 15px; */
  --transition: all 0.3s ease;
}

.E_Trainings_Course_price {
  background: linear-gradient(135deg, #2c3e50, #3498db);
  min-height: 100vh;
  padding: 2rem;
  position: relative;
  overflow-x: hidden;
}

.E_Trainings_Course_price .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.E_Trainings_Course_price header {
  text-align: center;
  color: white;
  margin-bottom: 3rem;
  position: relative;
  z-index: 2;
}

.E_Trainings_Course_price header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.E_Trainings_Course_price header p {
  font-size: 1.2rem;
  opacity: 0.9;
  color: #fff;
}

.E_Trainings_Course_price .currency-selector {
  background: rgba(255, 255, 255, 0.95);
  padding: 1.5rem;
  border-radius: 15px;
  margin-bottom: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.E_Trainings_Course_price .currency-selector select {
  width: 100%;
  padding: 1rem;
  border: 2px solid #3498db;
  border-radius: 15px;
  font-size: 1.1rem;
  background: white;
  color: #2c3e50;
  cursor: pointer;
  transition: all 0.3s ease;
}

.E_Trainings_Course_price .currency-selector select:hover {
  border-color: #e74c3c;
}

.E_Trainings_Course_price .plans-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.E_Trainings_Course_price .plan-card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.E_Trainings_Course_price .plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.E_Trainings_Course_price .plan-header {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.E_Trainings_Course_price .plan-title {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: bold;
}

.E_Trainings_Course_price .plan-price {
  font-size: 2.5rem;
  color: #e74c3c;
  font-weight: bold;
  margin: 1rem 0;
}

.E_Trainings_Course_price .plan-features {
  list-style: none;
  margin: 2rem 0;
}

.E_Trainings_Course_price .plan-features li {
  padding: 0.8rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.E_Trainings_Course_price .plan-features li:before {
  content: "✓";
  color: #2ecc71;
  margin-right: 1rem;
  font-weight: bold;
}

.E_Trainings_Course_price .plan-button {
  display: block;
  width: 100%;
  padding: 1rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 15px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  text-decoration: none;
  margin-top: 2rem;
}

.E_Trainings_Course_price .plan-button:hover {
  background: #e74c3c;
  transform: scale(1.05);
}

.E_Trainings_Course_price .language-toggle {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 1rem;
}

.E_Trainings_Course_price .language-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.E_Trainings_Course_price .language-btn.active {
  background: white;
  color: #2c3e50;
}

.E_Trainings_Course_price .arabic-text {
  direction: rtl;
  text-align: right;
  font-family: "Arial", sans-serif;
  line-height: 1.6;
}

.E_Trainings_Course_price .background-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.1;
}

.E_Trainings_Course_price .floating-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.E_Trainings_Course_price .shape {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: float 15s infinite linear;
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(-100vh) rotate(360deg);
  }
}

@media (max-width: 768px) {
  .E_Trainings_Course_price .container {
    padding: 1rem;
  }

  .E_Trainings_Course_price header h1 {
    font-size: 2rem;
  }

  .E_Trainings_Course_price .plans-container {
    grid-template-columns: 1fr;
  }
}
