.NavBar.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia) !important;
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia) !important;
}

.NavBar.bg-white\/80 {
  background-color: rgb(255 255 255 / 0.8) !important;
}

.NavBar.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.NavBar.border-b {
  border-bottom-width: 1px;
}

.NavBar.z-50 {
  z-index: 50;
}

.NavBar.top-0 {
  top: 0px;
}

.NavBar.right-0 {
  right: 0px;
}

.NavBar.left-0 {
  left: 0px;
}

.NavBar.fixed {
  position: fixed;
}

@media (min-width: 1024px) {
  .NavBar .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 640px) {
  .NavBar .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.NavBar .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.NavBar .max-w-7xl {
  max-width: 80rem;
}

.NavBar .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.NavBar .justify-between {
  justify-content: space-between;
}

.NavBar .items-center {
  align-items: center;
}

.NavBar .h-20 {
  height: 5rem;
}

.NavBar .flex {
  display: flex;
}

.NavBar .gap-8 {
  gap: 2rem;
}

.NavBar .items-center {
  align-items: center;
}

.NavBar .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.NavBar .font-semibold {
  font-weight: 600;
}

.NavBar .gap-2 {
  gap: 0.5rem;
}

.NavBar a {
  color: inherit;
  text-decoration: inherit;
}

@media (min-width: 1024px) {
  .NavBar .lg\:gap-2 {
    gap: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .NavBar .lg\:items-center {
    align-items: center;
  }
}

@media (min-width: 1024px) {
  .NavBar .lg\:flex {
    display: flex;
  }
}

.NavBar .hidden {
  display: none;
}

.NavBar .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.NavBar .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.NavBar .relative {
  position: relative;
}

.NavBar .gap-1 {
  gap: 0.25rem;
}

.NavBar .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.NavBar .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.NavBar .w-4 {
  width: 1rem;
}

.NavBar .h-4 {
  height: 1rem;
}

.NavBar .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.NavBar .rounded-full {
  border-radius: 9999px;
}

.NavBar .w-full {
  width: 100%;
}

.NavBar .h-0\.5 {
  height: 0.125rem;
}

.NavBar .left-0 {
  left: 0px;
}

.NavBar .bottom-0 {
  bottom: 0px;
}

.NavBar .absolute {
  position: absolute;
}

.NavBar .absolute .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.NavBar .group:hover .group-hover\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.NavBar .group:hover .group-hover\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.NavBar .text-blue-600,
.NavBar .hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.NavBar .hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.NavBar .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.NavBar .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.NavBar .p-2 {
  padding: 0.5rem;
}

.NavBar .rounded-md {
  border-radius: 0.375rem;
}

.NavBar .justify-center {
  justify-content: center;
}

.NavBar .items-center {
  align-items: center;
}

.NavBar .inline-flex {
  display: inline-flex;
}

.NavBar .group .sub-menu .lvl2,
.NavBar .group .sub-menu {
  --tw-text-opacity: 1;
  background: #fff;
  border-radius: 5px;
  /* border-top: 2px solid #573cff; */
  border-top: 2px solid rgb(37, 99, 235);
  -webkit-box-shadow: 0 1px 3px #0000004d;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 10px;
  position: absolute;
  text-align: left;
  transform: scaleY(0) translateZ(100px);
  transform-origin: top center;
  transition: 0.5s;
  transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.7s ease;
  visibility: hidden;
  width: 250px;
  z-index: 11;
}

.NavBar .group .sub-menu .lvl2 {
  top: -2px;
  right: -242px;
}

.NavBar .group .sub-menu {
  top: 111%;
  left: 0;
}

.NavBar .cursor-pointer:hover .lvl2,
.NavBar .group:hover .sub-menu {
  opacity: 1;
  transform: scaleY(1) translateZ(0);
  visibility: visible;
}

.NavBar .group .sub-menu ul li a {
  color: rgb(33, 37, 41);
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 8px 12px;
  position: relative;
  text-align: left;
  visibility: inherit !important;
  width: 100%;
}

.NavBar .group .sub-menu ul li a:hover {
  background: rgb(37, 99, 235);
  color: #fff;
}

/* ------------------------ mobile style --------------------------------------- */

.NavBar .dropdown .show {
  animation: showAnimation 0.5s ease-in-out;
  -moz-animation: showAnimation 0.5s ease-in-out;
  -webkit-animation: showAnimation 0.5s ease-in-out;
  display: block;
  max-height: 9999px;
  transform: scaleY(1);
  transition: max-height 1s ease-in-out;
}

.NavBar .dropdown .hide,
.NavBar .dropdown .show {
  transform-origin: 50% 0;
}

.NavBar .dropdown .dropdown-menu {
  list-style: none;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.NavBar .dropdown-menu.show {
  display: block;
  width: 80%;
}

.NavBar .dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  background-clip: padding-box;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  color: var(--bs-dropdown-color);
  display: none;
  font-size: var(--bs-dropdown-font-size);
  list-style: none;
  margin: 0;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  position: absolute;
  text-align: left;
  z-index: 1000;
}

.NavBar .dropdown-menu li a {
  box-shadow: inset 0 1px 0 #fff, inset 0 -1px 0 #d5d5d5;
  color: #0d0e14;
  display: block;
  padding: 6px;
  text-shadow: 0 -1px 0 #ffffff4d;
  transition: 0.5s;
}

.NavBar .sidebar-widget-menu ul li {
  list-style: none;
  margin-top: 10px;
  padding: 0 0 14px;
}

.NavBar .dropdown .dropdown-menu li a:hover {
  margin-left: 10px;
}

.NavBar .dropdown-menu li a:hover {
  margin-left: 10px;
}
