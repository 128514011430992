@media (min-width: 1024px) {
  .Catalogue_Des_Services .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 640px) {
  .Catalogue_Des_Services .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.Catalogue_Des_Services .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.Catalogue_Des_Services .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Catalogue_Des_Services .max-w-7xl {
  max-width: 80rem;
}

.Catalogue_Des_Services .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.Catalogue_Des_Services .text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.Catalogue_Des_Services .w-8 {
  width: 2rem;
}

.Catalogue_Des_Services .h-8 {
  height: 2rem;
}

.Catalogue_Des_Services .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.Catalogue_Des_Services .transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Catalogue_Des_Services .shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Catalogue_Des_Services .p-6 {
  padding: 1.5rem;
}

.Catalogue_Des_Services .rounded-lg {
  border-radius: 0.5rem;
}

.Catalogue_Des_Services .hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.Catalogue_Des_Services .bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
  .Catalogue_Des_Services .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .Catalogue_Des_Services .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.Catalogue_Des_Services .gap-6 {
  gap: 1.5rem;
}

.Catalogue_Des_Services .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.Catalogue_Des_Services .grid {
  display: grid;
}

.Catalogue_Des_Services .cursor-not-allowed {
  cursor: not-allowed;
}

.Catalogue_Des_Services .bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.Catalogue_Des_Services .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
