:root {
  --tw-translate-x: 0;
  --tw-translate-y: -50%;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

.Participants .border-\[\#25D366\] {
  border-left: 4px rgb(37 211 102);
}

.Participants .border-l-4 {
  border-left-width: 4px !important ;
}

.Participants .rounded-lg {
  border-radius: 0.5rem !important ;
}

.Participants .pl-10 {
  padding-left: 2.5rem !important;
}

.Participants .space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.Participants .transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.Participants .bg-\[\#25D366\] {
  --tw-bg-opacity: 1;
  background-color: rgb(37 211 102 / var(--tw-bg-opacity));
}

.Participants .text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.Participants .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.Participants .text-\[\#25D366\] {
  --tw-text-opacity: 1;
  color: rgb(37 211 102 / var(--tw-text-opacity));
}

.Participants .p-4 {
  padding: 1rem;
}

.Participants .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.Participants .border {
  border-width: 1px;
}

.Participants .hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.Participants .text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.Participants .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Participants .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.Participants .text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

.Participants .bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.Participants .gap-1 {
  gap: 0.25rem;
}

.Participants .bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.Participants .bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.Participants .text-\[\#25D366\] {
  --tw-text-opacity: 1;
  color: rgb(37 211 102 / var(--tw-text-opacity));
}

.Participants .hover\:text-\[\#128C7E\]:hover {
  --tw-text-opacity: 1;
  color: rgb(18 140 126 / var(--tw-text-opacity));
}

.Participants .hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.Participants .hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.Participants .bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.Participants .text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.Participants .bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.Participants .text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.Participants .bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.Participants .text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}
