.FAQSection.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.FAQSection.bg-navy-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 22 39 / var(--tw-bg-opacity));
}

.FAQSection.overflow-hidden {
  overflow: hidden;
}

.FAQSection.relative {
  position: relative;
}

.FAQSection .opacity-5 {
  opacity: 0.05;
}

.FAQSection .bg-center {
  background-position: center;
}

.FAQSection .bg-cover {
  background-size: cover;
}

.FAQSection
  .bg-\[url\(\'https\:\/\/images\.unsplash\.com\/photo-1557426272-fc759fdf7a8d\?auto\=format\&fit\=crop\&q\=80\'\)\] {
  background-image: url(https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?auto=format&fit=crop&q=80);
}

.FAQSection .inset-0 {
  inset: 0px;
}

.FAQSection .absolute {
  position: absolute;
}

.FAQSection .to-navy-900\/95 {
  --tw-gradient-to: rgb(26 34 53 / 0.95) var(--tw-gradient-to-position);
}

.FAQSection .from-navy-950 {
  --tw-gradient-from: #111627 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(17 22 39 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.FAQSection .bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
/* ----------------------------------- */
.FAQSection .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.FAQSection .max-w-6xl {
  max-width: 72rem;
}

.FAQSection .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.FAQSection .z-10 {
  z-index: 10;
}

.FAQSection .text-center {
  text-align: center;
}

.FAQSection .mb-16 {
  margin-bottom: 4rem;
}

.FAQSection .text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(139 143 255 / var(--tw-text-opacity));
}

.FAQSection .tracking-wide {
  letter-spacing: 0.025em;
}

.FAQSection .font-medium {
  font-weight: 500;
}

.FAQSection .font-heading {
  font-family: Clash Display, sans-serif !important;
}

.FAQSection .block {
  display: block;
}

.FAQSection .mb-4 {
  margin-bottom: 1rem;
}

@media (min-width: 1024px) {
  .FAQSection .lg\:text-5xl {
    font-size: 3rem !important;
    line-height: 1 !important;
  }
}

.FAQSection .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.FAQSection .font-bold {
  font-weight: 700;
}

.FAQSection .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.FAQSection .mb-6 {
  margin-bottom: 1.5rem;
}

.FAQSection blockquote,
.FAQSection dl,
.FAQSection dd,
.FAQSection h1,
.FAQSection h2,
.FAQSection h3,
.FAQSection h4,
.FAQSection h5,
.FAQSection h6,
.FAQSection hr,
.FAQSection figure,
.FAQSection p,
.FAQSection pre {
  margin: 0;
}

.FAQSection h1,
.FAQSection h2,
.FAQSection h3,
.FAQSection h4,
.FAQSection h5,
.FAQSection h6 {
  font-size: inherit;
  font-weight: inherit;
}

.FAQSection .text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.FAQSection .max-w-2xl {
  max-width: 42rem;
}

@media (min-width: 1024px) {
  .FAQSection .lg\:gap-8 {
    gap: 2rem;
  }
}

@media (min-width: 768px) {
  .FAQSection .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.FAQSection .gap-6 {
  gap: 1.5rem;
}

.FAQSection .grid {
  display: grid;
}

.FAQSection .duration-300 {
  transition-duration: 300ms;
}

.FAQSection .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.FAQSection .backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}

.FAQSection .bg-white\/5 {
  background-color: rgb(255 255 255 / 0.05);
}

.FAQSection .border-white\/10 {
  border-color: rgb(255 255 255 / 0.1) !important;
}

.FAQSection .border {
  border-width: 1px;
}

.FAQSection .rounded-xl {
  border-radius: 0.75rem;
}

.FAQSection .overflow-hidden {
  overflow: hidden;
}

.FAQSection .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.FAQSection .opacity-0 {
  opacity: 0;
}

.FAQSection .overflow-hidden {
  overflow: hidden;
}

.FAQSection .max-h-0 {
  max-height: 0px;
}

.FAQSection .leading-relaxed {
  line-height: 1.625;
}

.FAQSection .pb-6 {
  padding-bottom: 1.5rem;
}

.FAQSection .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.FAQSection .rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
